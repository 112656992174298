<app-header></app-header>
<mat-tab-group (selectedTabChange)="onTabChange($event)" [selectedIndex]="selectedMonthIndex">
  <ng-container *ngFor="let tab of tabs">
    <mat-tab [label]="tab.label">
      <ng-container [ngTemplateOutlet]="dataTableTemplate"
                    [ngTemplateOutletContext]="{'label': tab.label}"></ng-container>
    </mat-tab>
  </ng-container>
</mat-tab-group>



<ng-template #dataTableTemplate let-label="label">
  <ng-container *ngFor="let manager of Object.keys(data || {})">
    <ng-container *ngFor="let category of Object.keys(data[manager] || {})">
        <p> {{category}}</p>
      <ng-container *ngFor="let tables of Object.keys(data[manager]?.[category] || {})">
        <p> {{tables}}</p>
      <table mat-table [dataSource]="data[manager]?.[category]?.[tables]?.length ? data[manager]?.[category]?.[tables] : []">
        <ng-container *ngFor="let column of defaultColumns" [matColumnDef]="column">
          <mat-header-cell *matHeaderCellDef>{{ getColumnHeader(column) }}</mat-header-cell>
          <mat-cell *matCellDef="let element" (dblclick)="cell.onDoubleClick()">
            <app-salary-cell #cell
                             [cellData]="element[column]"
                             [tableData]="element[column]"
                             [tableId]="element['id']"
                             [columnName]=column>
            </app-salary-cell>
          </mat-cell>
          <mat-footer-cell *matFooterCellDef>{{ getColumnTotal(column, manager, category,tables) }}</mat-footer-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="defaultColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: defaultColumns;"></mat-row>
        <mat-footer-row *matFooterRowDef="defaultColumns"></mat-footer-row>
      </table>
        </ng-container>
    </ng-container>
  </ng-container>
</ng-template>
