import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from 'src/env/environment';

@Injectable()
export class MainService {

  constructor(private http: HttpClient) {
  }
  public getProjectMonthlySummary(month: number, year: number): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/core/project_summary/?month=${month}&year=${year}`, {})
  }
}


