import {Component, TemplateRef, ViewChild} from '@angular/core';
import {
  ITabInfo,
  Month,
  monthDaysMap,
  months,
  RomanceCompassTable
} from "../../shared/interfaces/interfaces";
import {finalize, map, Observable} from "rxjs";
import {TableDataService} from "../../services/table.service";
import {MatDialog} from "@angular/material/dialog";
import {MatTabChangeEvent} from "@angular/material/tabs";
import {ITableDay} from "../table/table.component";
import {
  CreateRomanceCompassTableComponent
} from "../create-romance-compass-table/create-romance-compass-table.component";
import {TableHeaderService} from "../../services/table-header.service";
import {SalaryService} from "../../services/salary.service";
import {delay} from "rxjs/operators";
import {YearService} from "../year-service/year-service";

@Component({
  selector: 'app-romance-compass',
  templateUrl: './romance-compass.component.html',
  styleUrls: ['./romance-compass.component.css']
})
export class RomanceCompassComponent {
  public tabs: ITabInfo[];

  public currentMonth: Month;

  public projectName: string = 'RomanceCompass'

  public months = months;

  public currentYear: number = this.yearService.currentYear

  public headersData$: Observable<any>;

  public defaultColumns = ["clientName", "account_id", "Total", "Delete"];

  public salaryColumns = ['client_full_name', "account_id", "total_sum", "gifts", "clear_total", "operator_fee", "gifts_percentage_value", "live_chat_bonus", "write_offs", "salary_dollars", ];

  public displayedColumns: string[];

  public salaryData$: any

  public operatorSalaryData: any

  public totalSalaryByMonth: number;

  public dataSource$: Observable<RomanceCompassTable[]>;

  public selectedMonthIndex: number = 0;

  @ViewChild("dataTableTemplate", {static: true})
  public dataTableTemplate: TemplateRef<any>;

  constructor(
    public tableData: TableDataService,
    public dialog: MatDialog,
    public headerData: TableHeaderService,
    public salaryData: SalaryService,
    public yearService: YearService
  ) {
  }

  public ngOnInit() {
    this._initTabs();
    this._initActiveTab();
    this._initColumns();
    this._initDataSource();
    this._getTableHeaderData();
    this._getSalaryData();
    this._getOperatorSalaryData();
    this._getTotalByMonth()
  }


  addTable() {
    this.dialog.open(CreateRomanceCompassTableComponent).afterClosed()
      .pipe(
        finalize(() => console.log('completed'))
      )
  }

  public onTabChange($event: MatTabChangeEvent) {
    this.currentMonth = $event.tab.textLabel as Month;
    this._initColumns();
    this._initDataSource();
    this._getTableHeaderData();
    this._getSalaryData();
    this._getOperatorSalaryData();
    this._getTotalByMonth()
  }


  private _initTabs(): void {
    this.tabs = [];
    this.months.forEach((month: Month) => {
      this.tabs.push({
        label: month,
        content: this.dataTableTemplate
      })
    })
  }

  private _initColumns() {
    const days: any = Array.from({length: monthDaysMap[this.currentMonth]}, (_, i) => (i + 1).toString())
    this.displayedColumns = [...this.defaultColumns.slice(0, 2), ...days, ...this.defaultColumns.slice(2)]
  }


  private _initDataSource(): void {
    this.dataSource$ = this.tableData.getTableData(this.months.indexOf(this.currentMonth) + 1, this.currentYear, "RomanceCompass")
      .pipe(
        map((data: RomanceCompassTable[]) =>
          data.map((data: RomanceCompassTable) => {
            const days: Record<string, ITableDay> = {};
            data.tabledata_set.forEach((data) => {
              days[data.day] = {
                data: data.data,
                id: data.id,
                is_day_off: data.is_day_off
              }
            })
            return {
              ...data,
              ...days,
            }
          })
        )
      )
  }

  private _initActiveTab() {
    this.selectedMonthIndex = new Date().getMonth();
    this.currentMonth = months[this.selectedMonthIndex];
  }

  private _getTableHeaderData() {
    this.headerData.getHeaderData(this.months.indexOf(this.currentMonth) + 1, this.currentYear, 'RomanceCompass').pipe(
      map((data) => {
        const dataSource: any[] = [];
        for (const key in data) {
          const new_data = data[key];
          const result = {'Site': key, ...new_data};
          dataSource.push(result);
        }
        return dataSource;
      })
    ).subscribe((data: any) => {
      this.headersData$ = data;
    });
  }

  private _getSalaryData() {
    this.salaryData.get_salary(this.months.indexOf(this.currentMonth) + 1, this.currentYear, 'RomanceCompass')
      .subscribe(
        data => {
          // this.salaryData$ = data;
          this._processSalaryData(data); // Вызов функции для обработки данных
        },
        error => {
          // Обработка ошибок подписки
        }
      );
  }

  private _processSalaryData(data: any) {
    this.salaryData$ = data
  }

  public copy_table() {
    this.tableData.copyTables('RomanceCompass', this.months.indexOf(this.currentMonth) + 1, this.currentYear).subscribe(() => {
      // Когда операция завершится, перезагружаем страницу
      location.reload();
    });
  }

  public _getOperatorSalaryData() {
    this.salaryData.get_operator_salary(this.months.indexOf(this.currentMonth) + 1, this.currentYear, 'RomanceCompass')
      .subscribe(
        data => {
          this._processOperatorSalaryData(data); // Вызов функции для обработки данных
        },
        error => {
          // Обработка ошибок подписки
        }
      );
  }

  private _processOperatorSalaryData(data: any) {
    this.operatorSalaryData = data
  }

  public _getTotalByMonth(){
    this.salaryData.total_salary_by_month(this.months.indexOf(this.currentMonth) + 1,this.currentYear,this.projectName).subscribe(
      (data: any)  => {
        this.totalSalaryByMonth = data['total_usdt']
      }
    )
  }

}
