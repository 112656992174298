import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {AuthService} from "./authentification.service";
import {environment} from 'src/env/environment';

@Injectable()

export class ClientSalaryService{
  constructor(private http: HttpClient, private token: AuthService){
  }

  public getClientSalaryData(month: number, year: number): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/client_salary/?month=${month}&year=${year}`, {})
  }

}

