<ng-container>
  <div *ngIf="isNotEditable else nonDefaultCell">
    <div *ngIf="!rowId else cellDataTemplate">{{sum}}</div>
  </div>

  <div *ngIf="isDelete()">
    <button (click)="deleteRow()" style="background: none; border: none; cursor: pointer;">
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="40" height="40" rx="18" fill="#FCD0CF"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M15 13V12C15 10.8954 15.8954 10 17 10H23C24.1046 10 25 10.8954 25 12V13H28C28.5523 13 29 13.4477 29 14C29 14.5523 28.5523 15 28 15H27V26C27 27.6569 25.6569 29 24 29H16C14.3431 29 13 27.6569 13 26V15H12C11.4477 15 11 14.5523 11 14C11 13.4477 11.4477 13 12 13H15ZM17 12H23V13H17V12ZM15 15H25V26C25 26.5523 24.5523 27 24 27H16C15.4477 27 15 26.5523 15 26V15Z" fill="#F5413D"/>
    <path d="M23 17H21V25H23V17Z" fill="#F5413D"/>
    <path d="M19 17H17V25H19V17Z" fill="#F5413D"/>
  </svg>
</button>
  </div>
  <ng-template #cellDataTemplate>
    {{cellData || ""}}
  </ng-template>


  <ng-template #nonDefaultCell>
    <div *ngIf="!showInput else inputTemplate">
      {{cellData?.data.toFixed(2)}}
    </div>
  </ng-template>

  <ng-template #inputTemplate>
    <input #dataInput (keyup.enter)="formControl.valid && (onEnter())" (focusout)="onClickOutside()"
           [formControl]="formControl">
  </ng-template>

</ng-container>
