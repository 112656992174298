import {Component, OnInit, SimpleChanges, TemplateRef, ViewChild, ViewEncapsulation} from '@angular/core';
import {MatTabChangeEvent} from "@angular/material/tabs";
import {defaultColumns} from "../table/constants/default-columns.constant";
import {BehaviorSubject, finalize, map, Observable} from "rxjs";
import {months, OnlyFansTable, TableData} from "../../shared/interfaces/interfaces";
import {ITableDay} from "../table/table.component";

import {TableDataService} from "../../services/table.service";
import {MatDialog} from "@angular/material/dialog";
import {CreateTableComponent} from "../create-table/create-table.component";
import {YearService} from "../year-service/year-service";
import {MainService} from "../../services/main.service";
import {MatTableDataSource} from "@angular/material/table";

export interface ITabInfo {
  label: Month;
  content: TemplateRef<any>;
}

export const enum Month {
  JANUARY = "January",
  FEBRUARY = "February",
  MARCH = "March",
  APRIL = "April",
  MAY = "May",
  JUNE = "June",
  JULY = "July",
  AUGUST = "August",
  SEPTEMBER = "September",
  OCTOBER = "October",
  NOVEMBER = "November",
  DECEMBER = "December"
}
export const monthDaysMap: Record<Month, number> = {
  [Month.JANUARY]: 31,
  [Month.FEBRUARY]: 29,
  [Month.MARCH]: 31,
  [Month.APRIL]: 30,
  [Month.MAY]: 31,
  [Month.JUNE]: 30,
  [Month.JULY]: 31,
  [Month.AUGUST]: 31,
  [Month.SEPTEMBER]: 30,
  [Month.OCTOBER]: 31,
  [Month.NOVEMBER]: 30,
  [Month.DECEMBER]: 31
}

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class MainComponent implements OnInit {

  public selectedMonth: number = new Date().getMonth() + 1; // Установка текущего месяца по умолчанию (+1, так как getMonth() возвращает от 0 до 11)

  public months = [
    { name: 'January', value: '1' },
    { name: 'February', value: '2' },
    { name: 'March', value: '3' },
    { name: 'April', value: '4' },
    { name: 'May', value: '5' },
    { name: 'June', value: '6' },
    { name: 'July', value: '7' },
    { name: 'August', value: '8' },
    { name: 'September', value: '9' },
    { name: 'October', value: '10' },
    { name: 'November', value: '11' },
    { name: 'December', value: '12' }
  ];

  public currentYear: number = this.yearService.currentYear

  public dataSource$: BehaviorSubject<MatTableDataSource<any>> = new BehaviorSubject(new MatTableDataSource<any>());

  public dataSource: MatTableDataSource<any>;

  public data: any

  public month: number

  public displayedColumns: string[] = ['Site', 'Total']

  @ViewChild("dataTableTemplate", {static: true})
  public dataTableTemplate: TemplateRef<any>;

  constructor(
    public tableData: TableDataService,
    public dialog: MatDialog,
    public yearService: YearService,
    public service: MainService,

  ) {
    this.dataSource = new MatTableDataSource<any>();
    this.selectedMonth  = new Date().getMonth() + 1;
    this.getProjectSummary(this.selectedMonth, this.currentYear)
  }

  public ngOnInit() {
    // this.getProjectSummary(this.selectedMonth, this.currentYear)
  }


  public getProjectSummary(month: number, year: number) {
    this.service.getProjectMonthlySummary(month, year).subscribe(data => {
      this.data = data;
      this.initDataSource()
    });
  }

  public initDataSource() {
    const dataSourceArray = [];

    for (const site in this.data) {
      if (this.data.hasOwnProperty(site)) {
        dataSourceArray.push({
          Site: site,
          Total: this.data[site] || 0
        });
      }
    }

    this.dataSource = new MatTableDataSource<any>(dataSourceArray);
  }

  public onMonthChange(event: Event) {
    this.getProjectSummary(this.selectedMonth, this.currentYear)
    this.initDataSource()
  }

}
