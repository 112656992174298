<app-header></app-header>


<div class="card mb-3" style="max-width: 700px; margin: 10px;">
  <div class="row g-0">
    <div class="col-md-4">
      <img src="{{clientData.photo}}" class="img-fluid rounded-start" alt="...">
    </div>
    <div class="col-md-8">
      <div *ngIf="!editModeControls['BaseInfo']">
        <div class="card-body">
          <div class="row">
            <div class="col-md-11">
              <p class="card-text">{{ clientData.full_name }}</p>
              <p class="card-text">
              <p>{{ clientData.country }}</p>
              <p class="card-text">{{ clientData.date_of_birth }}</p>
              <p class="card-text">
                CM: {{ this.getClientManager().first_name }} {{ this.getClientManager().last_name }}</p>
              <p class="card-text">Status: {{ clientData.status }}</p>
            </div>
            <div class="col-md-1">
              <button class="btn btn-primary" (click)="toggleEditMode('BaseInfo'); _ChangeBaseInfoForm()"
                      style="margin: 5px">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                     class="bi bi-pencil-square"
                     viewBox="0 0 16 16">
                  <path
                    d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                  <path fill-rule="evenodd"
                        d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="editModeControls['BaseInfo']">
        <div class="card-body">
          <div class="row">
            <div class="col-md-11">
              <form class="my-form" [formGroup]="changeBasicInfo" (ngSubmit)="changeBaseInfo()">
                <mat-form-field>
                  <mat-label>Enter name</mat-label>
                  <input matInput placeholder="Name" formControlName="name">
                </mat-form-field>
                <mat-form-field>
                  <mat-label>Enter surname</mat-label>
                  <input matInput placeholder="Surname" formControlName="surname">
                </mat-form-field>
                <p class="card-text">
                  <mat-form-field>
                    <mat-label>Enter country</mat-label>
                    <input matInput placeholder="Country" formControlName="country">
                  </mat-form-field>

                  <mat-form-field>
                    <mat-label>Enter new URL photo</mat-label>
                    <input matInput placeholder="Photo" formControlName="photo">
                  </mat-form-field>

                  <mat-form-field>
                    <mat-label>Enter date of birth</mat-label>
                    <input matInput placeholder="Date of Birth DD.MM.YYYY" formControlName="dateOfBirth"
                           value="{{ clientData.date_of_birth}}">
                  </mat-form-field>

                  <mat-form-field>
                    <mat-label>CM:</mat-label>
                    <mat-select formControlName="managers" [(ngModel)]="initialManager">
                      <ng-container *ngFor="let manager of clientManagers">
                        <mat-option
                          value="{{manager.id | number}}">{{ manager.first_name }} {{ manager.last_name }}
                        </mat-option>
                      </ng-container>
                    </mat-select>
                  </mat-form-field>
                  <mat-form-field>
                    <mat-label>Status:</mat-label>
                    <mat-select formControlName="status" [(ngModel)]="initialStatus">
                      <ng-container *ngFor="let status of statusList">
                        <mat-option
                          value="{{status}}"> {{status}}
                        </mat-option>
                      </ng-container>
                    </mat-select>
                  </mat-form-field>

                  <button class="btn btn-success" [disabled]="changeBasicInfo.invalid" type="submit" mat-raised-button
                          (click)="toggleEditMode('BaseInfo'); changeBaseInfo()" style="margin:5px">Save
                  </button>
              </form>
            </div>

          </div>
        </div>


      </div>

      <!--      тут надо написать форм группу для полей-->
      <!--      <div formGroup="">-->
      <!---->
      <!---->
      <!--      </div>-->

<!--      <div class="container text-center">
        <div class="row">
          <div class="col">
            <div class="form-check">
              <label class="form-check-label" for="AD">
                AD
              </label>
              <input class="form-check-input" type="checkbox" value="" id="AD" (change)="true" #ADBox>
            </div>
          </div>
          <div class="col">
            <div class="form-check">
              <label class="form-check-label" for="D">
                D
              </label>
              <input class="form-check-input" type="checkbox" value="" id="D" (change)="true" #DBox>
            </div>
          </div>
          <div class="col">
            <div class="form-check">
              <label class="form-check-label" for="CD">
                CD
              </label>
              <input class="form-check-input" type="checkbox" value="" id="CD" (change)="true" #CDBox>
            </div>
          </div>
          <div class="col">
            <div class="form-check"><label class="form-check-label" for="GB">
              GB
            </label>
              <input class="form-check-input" type="checkbox" value="" id="GB" (change)="true" #GBBox>
            </div>
          </div>
          <div class="col">
            <div class="form-check"><label class="form-check-label" for="f">
              F
            </label>
              <input class="form-check-input" type="checkbox" value="" id="f" (change)="true" #FBox>
            </div>
          </div>
          <div class="col">
            <div class="form-check"><label class="form-check-label" for="OF">
              OF
            </label>
              <input class="form-check-input" type="checkbox" value="" id="OF" (change)="true" #OFBox>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div>
  <table class="table table-bordered" style="margin: 10px">
    <thead>
    <tr class="header">
      <th scope="col">SITE</th>
      <th scope="col">OPERATOR 1</th>
      <th scope="col">OPERATOR 2</th>
    </tr>
    </thead>
    <tbody>
    <tr>
      <th scope="row">AnastasiaDating</th>
      <td>Mark</td>
      <td>Otto</td>
    </tr>
    <tr>
      <th scope="row">DateMe</th>
      <td>Jacob</td>
      <td>Thornton</td>
    </tr>
    <tr>
      <th scope="row">CharmDate</th>
      <td>Larry the Bird</td>
      <td>asdasd</td>
    </tr>
    <tr>
      <th scope="row">GoldenBride</th>
      <td>Jacob</td>
      <td>Thornton</td>
    </tr>
    <tr>
    <tr>
      <th scope="row">Fansly</th>
      <td>Jacob</td>
      <td>Thornton</td>
    </tr>
    <tr>
    <tr>
      <th scope="row">OnlyFans</th>
      <td>Jacob</td>
      <td>Thornton</td>
    </tr>
    <tr>
    </tbody>
  </table>
</div>

<div style="width: 500px; margin: 10px">

  <div *ngIf="!editModeControls['Socials']">

    <div>
      <div class="row">
        <div class="col">
          <p class="socials" style="color: red;">SOCIALS:</p>
        </div>
        <div class="col">
          <button class="btn btn-primary" (click)="toggleEditMode('Socials')" style="margin: 5px">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                 class="bi bi-pencil-square"
                 viewBox="0 0 16 16">
              <path
                d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
              <path fill-rule="evenodd"
                    d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
            </svg>
          </button>
        </div>
      </div>
    </div>
    <table class="table-bordered" style="width: 500px;">
      <tbody>
      <tr>
        <th>Instagramm</th>
        <td>@asdlkjasd</td>
      </tr>
      <tr>
        <th>Facebook</th>
        <td>asdflkjasdlkf</td>
      </tr>
      <tr>
        <th>Reddit</th>
        <td>asdflkjasdlkf</td>
      </tr>
      <tr>
        <th>Twitter</th>
        <td>asdfasdfasdf</td>

      </tr>
      </tbody>
    </table>
  </div>

  <div *ngIf="editModeControls['Socials']">

    <div>
      <div class="row">
        <div class="col">
          <p class="socials" style="color: red;">SOCIALS:</p>
        </div>
        <div class="col">
          <button class="btn btn-success" (click)="toggleEditMode('BaseInfo')" style="margin:5px">Save</button>
        </div>
      </div>
    </div>


    <table class="table-bordered" style="width: 500px;">
      <tbody>
      <tr>
        <th>Instagramm</th>
        <td>@asdlkjasd</td>
      </tr>
      <tr>
        <th>Facebook</th>
        <td>asdflkjasdlkf</td>
      </tr>
      <tr>
        <th>Reddit</th>
        <td>asdflkjasdlkf</td>
      </tr>
      <tr>
        <th>Twitter</th>
        <td>asdfasdfasdf</td>

      </tr>
      </tbody>
    </table>
  </div>


</div>


<div *ngIf="ADBox.checked">

  <div *ngIf="!editModeControls['AnastasiaDating']">

    <div class="container">
      <div class="row">
        <div class="col-lg-8"><h1>Anastasia Dating</h1>
        </div>
        <div class="col-lg-2"></div>
        <div class="col-lg-2">
          <button class="btn btn-primary" (click)="toggleEditMode('AnastasiaDating')" style="margin: 5px">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                 class="bi bi-pencil-square"
                 viewBox="0 0 16 16">
              <path
                d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
              <path fill-rule="evenodd"
                    d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
            </svg>
          </button>
        </div>

      </div>

      <div [formGroup]="myForm">
        <div formArrayName="AnastasiaDating">
          <div *ngFor="let field of fieldList('AnastasiaDating').controls; let i = index">
            <div [formGroupName]="i">
              <div class="row">
                <div class="col-lg-6">
                  <input type="text" class="form-control" readonly value="{{ field.get('name')?.value }}">
                </div>
                <div class="col-lg-6">
                  <input type="text" class="form-control" readonly value="{{ field.get('value')?.value }}">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="editModeControls['AnastasiaDating']">
    <div class="container">
      <div class="row">
        <div class="col-lg-8">
          <h1>Anastasia Dating</h1>
        </div>
        <div class="col-lg-2">
          <button type="button" class="btn btn-success" (click)="addField('AnastasiaDating')" style="margin: 5px;">+
          </button>
        </div>
        <div class="col-lg-2">
          <button class="btn btn-success" (click)="toggleEditMode('AnastasiaDating')" style="margin:5px">Save</button>

        </div>

      </div>
      <div [formGroup]="myForm">
        <div formArrayName="AnastasiaDating">
          <div *ngFor="let field of fieldList('AnastasiaDating').controls; let i = index">
            <div [formGroupName]="i">
              <div class="row">
                <div class="col-lg-6">
                  <input class="form-control" type="text" formControlName="name"/>
                </div>
                <div class="col-lg-5">
                  <input class="form-control" type="text" formControlName="value"/>
                </div>
                <div class="col-lg-1">
                  <button type="button" class="btn btn-danger" (click)="removeField(i, 'AnastasiaDating')"
                          style="margin-top: 7px;padding-left: 0px;padding-top: 2px;padding-bottom: 2px;padding-right: 0px;"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x"
                         viewBox="0 0 16 16" style="
    padding-left: 0px;
    height: 20px;
    width: 23px;
">
                      <path
                        d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="DBox.checked">
  <div *ngIf="!editModeControls['DateMe']">

    <div class="container">
      <div class="row">
        <div class="col-lg-4"><h1>DateMe</h1>
        </div>
        <div class="col-lg-4"></div>
        <div class="col-lg-4">
          <button class="btn btn-primary" (click)="toggleEditMode('DateMe')" style="margin: 5px">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                 class="bi bi-pencil-square"
                 viewBox="0 0 16 16">
              <path
                d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
              <path fill-rule="evenodd"
                    d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
            </svg>
          </button>
        </div>

      </div>

      <div [formGroup]="myForm">
        <div formArrayName="DateMe">
          <div *ngFor="let field of fieldList('DateMe').controls; let i = index">
            <div [formGroupName]="i">
              <div class="row">
                <div class="col-lg-6">
                  <input type="text" class="form-control" readonly value="{{ field.get('name')?.value }}">
                </div>
                <div class="col-lg-6">
                  <input type="text" class="form-control" readonly value="{{ field.get('value')?.value }}">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="editModeControls['DateMe']">
    <div class="container">
      <div class="row">
        <div class="col-lg-8">
          <h1>DateMe</h1>
        </div>
        <div class="col-lg-2">
          <button type="button" class="btn btn-success" (click)="addField('DateMe')" style="margin: 5px;">+</button>
        </div>
        <div class="col-lg-2">
          <button class="btn btn-success" (click)="toggleEditMode('DateMe')" style="margin:5px">Save</button>

        </div>

      </div>
      <div [formGroup]="myForm">
        <div formArrayName="DateMe">
          <div *ngFor="let field of fieldList('DateMe').controls; let i = index">
            <div [formGroupName]="i">
              <div class="row">
                <div class="col-lg-6">
                  <input class="form-control" type="text" formControlName="name"/>
                </div>
                <div class="col-lg-5">
                  <input class="form-control" type="text" formControlName="value"/>
                </div>
                <div class="col-lg-1">
                  <button type="button" class="btn btn-danger" (click)="removeField(i, 'DateMe')"
                          style="margin-top: 7px;padding-left: 0px;padding-top: 2px;padding-bottom: 2px;padding-right: 0px;"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x"
                         viewBox="0 0 16 16" style="
    padding-left: 0px;
    height: 20px;
    width: 23px;
">
                      <path
                        d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


</div>

<div *ngIf="CDBox.checked">

  <div *ngIf="!editModeControls['CharmDate']">

    <div class="container">
      <div class="row">
        <div class="col-lg-4"><h1>CharmDate</h1>
        </div>
        <div class="col-lg-4"></div>
        <div class="col-lg-4">
          <button class="btn btn-primary" (click)="toggleEditMode('CharmDate')" style="margin: 5px">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                 class="bi bi-pencil-square"
                 viewBox="0 0 16 16">
              <path
                d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
              <path fill-rule="evenodd"
                    d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
            </svg>
          </button>
        </div>

      </div>

      <div [formGroup]="myForm">
        <div formArrayName="CharmDate">
          <div *ngFor="let field of fieldList('CharmDate').controls; let i = index">
            <div [formGroupName]="i">
              <div class="row">
                <div class="col-lg-6">
                  <input type="text" class="form-control" readonly value="{{ field.get('name')?.value }}">
                </div>
                <div class="col-lg-6">
                  <input type="text" class="form-control" readonly value="{{ field.get('value')?.value }}">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="editModeControls['CharmDate']">

    <div class="container">
      <div class="row">
        <div class="col-lg-8">
          <h1>Charm Date</h1>
        </div>
        <div class="col-lg-2">
          <button type="button" class="btn btn-success" (click)="addField('CharmDate')" style="margin: 5px;">+</button>
        </div>
        <div class="col-lg-2">
          <button class="btn btn-success" (click)="toggleEditMode('CharmDate')" style="margin:5px">Save</button>

        </div>

      </div>
      <div [formGroup]="myForm">
        <div formArrayName="CharmDate">
          <div *ngFor="let field of fieldList('CharmDate').controls; let i = index">
            <div [formGroupName]="i">
              <div class="row">
                <div class="col-lg-6">
                  <input class="form-control" type="text" formControlName="name"/>
                </div>
                <div class="col-lg-5">
                  <input class="form-control" type="text" formControlName="value"/>
                </div>
                <div class="col-lg-1">
                  <button type="button" class="btn btn-danger" (click)="removeField(i,'CharmDate')"
                          style="margin-top: 7px;padding-left: 0px;padding-top: 2px;padding-bottom: 2px;padding-right: 0px;"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x"
                         viewBox="0 0 16 16" style="
    padding-left: 0px;
    height: 20px;
    width: 23px;
">
                      <path
                        d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


</div>

<div *ngIf="GBBox.checked">

  <div *ngIf="!editModeControls['GoldenBride']">

    <div class="container">
      <div class="row">
        <div class="col-lg-4"><h1>Golden Bride</h1>
        </div>
        <div class="col-lg-4"></div>
        <div class="col-lg-4">
          <button class="btn btn-primary" (click)="toggleEditMode('GoldenBride')" style="margin: 5px">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                 class="bi bi-pencil-square"
                 viewBox="0 0 16 16">
              <path
                d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
              <path fill-rule="evenodd"
                    d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
            </svg>
          </button>
        </div>

      </div>

      <div [formGroup]="myForm">
        <div formArrayName="GoldenBride">
          <div *ngFor="let field of fieldList('GoldenBride').controls; let i = index">
            <div [formGroupName]="i">
              <div class="row">
                <div class="col-lg-6">
                  <input type="text" class="form-control" readonly value="{{ field.get('name')?.value }}">
                </div>
                <div class="col-lg-6">
                  <input type="text" class="form-control" readonly value="{{ field.get('value')?.value }}">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="editModeControls['GoldenBride']">
    <div class="container">
      <div class="row">
        <div class="col-lg-8">
          <h1>Golden Bride</h1>
        </div>
        <div class="col-lg-2">
          <button type="button" class="btn btn-success" (click)="addField('GoldenBride')" style="margin: 5px;">+
          </button>
        </div>
        <div class="col-lg-2">
          <button class="btn btn-success" (click)="toggleEditMode('GoldenBride')" style="margin:5px">Save</button>

        </div>

      </div>
      <div [formGroup]="myForm">
        <div formArrayName="GoldenBride">
          <div *ngFor="let field of fieldList('GoldenBride').controls; let i = index">
            <div [formGroupName]="i">
              <div class="row">
                <div class="col-lg-6">
                  <input class="form-control" type="text" formControlName="name"/>
                </div>
                <div class="col-lg-5">
                  <input class="form-control" type="text" formControlName="value"/>
                </div>
                <div class="col-lg-1">
                  <button type="button" class="btn btn-danger" (click)="removeField(i, 'GoldenBride')"
                          style="margin-top: 7px;padding-left: 0px;padding-top: 2px;padding-bottom: 2px;padding-right: 0px;"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x"
                         viewBox="0 0 16 16" style="
    padding-left: 0px;
    height: 20px;
    width: 23px;
">
                      <path
                        d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


</div>

<div *ngIf="FBox.checked">

  <div *ngIf="!editModeControls['Fansly']">

    <div class="container">
      <div class="row">
        <div class="col-lg-4"><h1>Fansly</h1>
        </div>
        <div class="col-lg-4"></div>
        <div class="col-lg-4">
          <button class="btn btn-primary" (click)="toggleEditMode('Fansly')" style="margin: 5px">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                 class="bi bi-pencil-square"
                 viewBox="0 0 16 16">
              <path
                d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
              <path fill-rule="evenodd"
                    d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
            </svg>
          </button>
        </div>

      </div>

      <div [formGroup]="myForm">
        <div formArrayName="Fansly">
          <div *ngFor="let field of fieldList('Fansly').controls; let i = index">
            <div [formGroupName]="i">
              <div class="row">
                <div class="col-lg-6">
                  <input type="text" class="form-control" readonly value="{{ field.get('name')?.value }}">
                </div>
                <div class="col-lg-6">
                  <input type="text" class="form-control" readonly value="{{ field.get('value')?.value }}">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="editModeControls['Fansly']">
    <div class="container">
      <div class="row">
        <div class="col-lg-8">
          <h1>Fansly</h1>
        </div>
        <div class="col-lg-2">
          <button type="button" class="btn btn-success" (click)="addField('Fansly')" style="margin: 5px;">+</button>
        </div>
        <div class="col-lg-2">
          <button class="btn btn-success" (click)="toggleEditMode('Fansly')" style="margin:5px">Save</button>

        </div>

      </div>
      <div [formGroup]="myForm">
        <div formArrayName="Fansly">
          <div *ngFor="let field of fieldList('Fansly').controls; let i = index">
            <div [formGroupName]="i">
              <div class="row">
                <div class="col-lg-6">
                  <input class="form-control" type="text" formControlName="name"/>
                </div>
                <div class="col-lg-5">
                  <input class="form-control" type="text" formControlName="value"/>
                </div>
                <div class="col-lg-1">
                  <button type="button" class="btn btn-danger" (click)="removeField(i, 'Fansly')"
                          style="margin-top: 7px;padding-left: 0px;padding-top: 2px;padding-bottom: 2px;padding-right: 0px;"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x"
                         viewBox="0 0 16 16" style="
    padding-left: 0px;
    height: 20px;
    width: 23px;
">
                      <path
                        d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


</div>

<div *ngIf="OFBox.checked">

  <div *ngIf="!editModeControls['OnlyFans']">

    <div class="container">
      <div class="row">
        <div class="col-lg-4"><h1>OnlyFans</h1>
        </div>
        <div class="col-lg-4"></div>
        <div class="col-lg-4">
          <button class="btn btn-primary" (click)="toggleEditMode('OnlyFans')" style="margin: 5px">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                 class="bi bi-pencil-square"
                 viewBox="0 0 16 16">
              <path
                d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
              <path fill-rule="evenodd"
                    d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
            </svg>
          </button>
        </div>

      </div>

      <div [formGroup]="myForm">
        <div formArrayName="OnlyFans">
          <div *ngFor="let field of fieldList('OnlyFans').controls; let i = index">
            <div [formGroupName]="i">
              <div class="row">
                <div class="col-lg-6">
                  <input type="text" class="form-control" readonly value="{{ field.get('name')?.value }}">
                </div>
                <div class="col-lg-6">
                  <input type="text" class="form-control" readonly value="{{ field.get('value')?.value }}">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="editModeControls['OnlyFans']">
    <div class="container">
      <div class="row">
        <div class="col-lg-8">
          <h1>OnlyFans</h1>
        </div>
        <div class="col-lg-2">
          <button type="button" class="btn btn-success" (click)="addField('OnlyFans')" style="margin: 5px;">+</button>
        </div>
        <div class="col-lg-2">
          <button class="btn btn-success" (click)="toggleEditMode('OnlyFans')" style="margin:5px">Save</button>

        </div>

      </div>
      <div [formGroup]="myForm">
        <div formArrayName="OnlyFans">
          <div *ngFor="let field of fieldList('OnlyFans').controls; let i = index">
            <div [formGroupName]="i">
              <div class="row">
                <div class="col-lg-6">
                  <input class="form-control" type="text" formControlName="name"/>
                </div>
                <div class="col-lg-5">
                  <input class="form-control" type="text" formControlName="value"/>
                </div>
                <div class="col-lg-1">
                  <button type="button" class="btn btn-danger" (click)="removeField(i, 'OnlyFans')"
                          style="margin-top: 7px;padding-left: 0px;padding-top: 2px;padding-bottom: 2px;padding-right: 0px;"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x"
                         viewBox="0 0 16 16" style="
    padding-left: 0px;
    height: 20px;
    width: 23px;
">
                      <path
                        d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  -->
</div>

