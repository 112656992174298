import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, Renderer2} from '@angular/core';
import {ITableDay} from "../../table.component";
import {FormControl, Validators} from "@angular/forms";
import {TableCellService} from "../../../../services/tabledata.service";
import {MatDialog} from "@angular/material/dialog";
import {DeleteRowPopupComponent} from "../delete-row-popup/delete-row-popup.component";
import {TableDataService} from "../../../../services/table.service";

@Component({
  selector: 'app-table-cell',
  templateUrl: './table-cell.component.html',
  styleUrls: ['./table-cell.component.css'],

})
export class TableCellComponent implements OnInit {
  @Input() rowId: number;

  @Input() day: number;

  @Input() isDefaultColumn: boolean;

  @Input() cellData: ITableDay;

  @Input() columnName: string;

  @Input()
  public sum: number | string;

  public formControl: FormControl;

  public showInput = false;

  @Input() isDayOff: boolean;

  public get isNotEditable(): boolean {
    return this.isDefaultColumn || !this.rowId
  }

  @ViewChild("dataInput") public dataInput: ElementRef;

  @Output()
  public updateTable: EventEmitter<boolean> = new EventEmitter<boolean>(false);

  constructor(
    private service: TableCellService,
    public dialog: MatDialog,
    private http: TableDataService,
  ) {
  }

  public onDoubleClick() {
    this.showInput = true;
    setTimeout(() => {
      this.dataInput.nativeElement.focus();
    }, 0)
  }

  public checkDayOff() {
    if (this.cellData && this.cellData.is_day_off == true) {
      this.isDayOff = true
    }
  }

  public onEnter() {
   if (this.cellData && this.formControl.value === "-") {
    this.service.patch_table_cell(this.cellData.id, this.cellData.data, true);
  } else if (this.cellData && this.formControl.value === "+") {
    this.service.patch_table_cell(this.cellData.id, this.cellData.data, false);
  } else if (this.formControl.value === "-" && !this.cellData) {
    this.service.create_new_table_cell_with_dayoff(this.rowId, this.day, true).subscribe(
      data => console.log(data)
    );
  } else if (this.formControl.value === "+" && !this.cellData) {
    this.service.create_new_table_cell_with_dayoff(this.rowId, this.day, false).subscribe(
      data => console.log(data)
    );
  }else if (this.cellData) {
    this.service.patch_table_cell(this.cellData.id, this.formControl.value,this.cellData.is_day_off);
    this.cellData.data = +this.formControl.value;
  }
   else {
    this.service.create_new_table_cell(this.rowId, this.day, this.formControl.value)
      .subscribe((data) => {
        this.cellData = {
          id: data.id,
          tableID: data.tableID,
          data: data.data,
          day: data.day,
          is_day_off: data.is_day_off
        };
      });
  }
  this.showInput = false;
  this.updateTable.emit(true);
}

  public ngOnInit() {
    this._initFormControl()
    this.checkDayOff()
  }

  private _initFormControl() {
    this.formControl = (this.isDefaultColumn)
      ? new FormControl
      : new FormControl<string>(this.cellData?.data.toString(), [Validators.required, Validators.pattern('^-?[0-9]+([.,][0-9]+)?|[+-]$')]);
  }

  public onClickOutside() {
    this.showInput = false;
  }

  public isDelete(){
    return this.columnName == 'Delete'
  }

  public deleteRow(){
    const dialogRef = this.dialog.open(DeleteRowPopupComponent);
    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.http.deleteTable(this.rowId).subscribe()
        location.reload();
      } else {
      }
    });


  }
}
