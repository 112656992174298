import {Component, OnInit} from '@angular/core';
import {MatDialog} from "@angular/material/dialog";
import {AuthService} from "../../services/authentification.service";
import {Router} from "@angular/router";
import {YearService} from "../../components/year-service/year-service";

export interface ISiteTab {
  hasPermissions: boolean;
  routerLink: string;
  label: string;
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  public sitesTabs: ISiteTab[];

  public isLoggin = this.auth.isAuthenticated()

  public selectedYear: string = this.yearService.currentYear.toString();

  constructor(public dialog: MatDialog, public auth: AuthService, public router: Router, public yearService: YearService) {

  }

  public ngOnInit() {
    this._initSiteTabs();
  }

  onYearChange(event: any) {
    this.yearService.currentYear = parseInt(event.target.value, 10);
  }


  public hasPermission(requiredPermissionList: string[]) {
    let perms = localStorage.getItem('perms')
    if (perms !== null) {
      let perm = Object.values(JSON.parse(perms));
      return requiredPermissionList.some((permission: unknown) => perm.includes(permission));
    } else {
      return false
    }
  }

  public logout() {
    this.auth.logout()
    this.isLoggin = false
    this.router.navigate(["login"])
  }

  private _initSiteTabs(): void {
    this.sitesTabs = [
      {
        hasPermissions: true,
        routerLink: "/workpage",
        label: "Heaven"
      },
      {
        hasPermissions: true,
        routerLink: "/clientlist",
        label: "Clients",
      },
      {
        hasPermissions: true,
        routerLink: "/client_salary",
        label: "Client Salary",
      },
      {
        hasPermissions: this.hasPermission(['[GoldenBride]Operator', '[GoldenBride]Project Manager']),
        routerLink: "/goldenbride",
        label: "Golden Bride",
      },
      {
        hasPermissions: this.hasPermission(['[CharmDate]Operator', '[CharmDate]Project Manager']),
        routerLink: "/charmdate",
        label: "Charm Date",
      },
      {
        hasPermissions: this.hasPermission(['[DateMe]Operator', '[DateMe]Project Manager']),
        routerLink: "/dateme",
        label: "Date Me",
      },
      {
        hasPermissions: this.hasPermission(['[JumpForLove]Operator', '[JumpForLove]Project Manager']),
        routerLink: "/jumpforlove",
        label: "Jump For Love",
      },
      {
        hasPermissions: this.hasPermission(['[RomanceCompass]Operator', '[RomanceCompass]Project Manager']),
        routerLink: "/romancecompass",
        label: "Romance Compass",
      },
      {
        hasPermissions: this.hasPermission(['[AnastasiaDating]Operator', '[AnastasiaDating]Project Manager']),
        routerLink: "/anastasiadating",
        label: "Anastasia Dating",
      },
      {
        hasPermissions: this.hasPermission(['[Fansly]Operator', '[Fansly]Project Manager']),
        routerLink: "/fansly",
        label: "Fansly",
      },
      {
        hasPermissions: this.hasPermission(['[Fancentro]Operator', '[Fancentro]Project Manager']),
        routerLink: "/fancentro",
        label: "Fancentro"
      },
      {
        hasPermissions: this.hasPermission(['[OnlyFans]Operator', '[OnlyFans]Project Manager']),
        routerLink: "/onlyfans",
        label: "OnlyFans"
      }
    ]
  }
}
