import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {
  ChangeClientBaseInfo,
  ClientManager,
  ClientProfile,
  CreateClient,
  shortClientList
} from "../shared/interfaces/interfaces";
import {Observable} from "rxjs";
import {AuthService} from "./authentification.service";
import { environment } from 'src/env/environment';

@Injectable()

export class ClientService{
  constructor(private http: HttpClient, private token: AuthService){
  }

  public getClientList(): Observable<{[key: string]: { [key: string]: any[] }}>{
    return this.http.get<{[key: string]: { [key: string]: any[] }}>(`${environment.apiUrl}/users/client_list/`, {})
  }

  public getClientProfile(id: number): Observable<ClientProfile>{
    return this.http.get<ClientProfile>(`${environment.apiUrl}/users/client_api/${id}/`, {})
  }


  public getClientManagers(): Observable<ClientManager[]>{
    return this.http.get<ClientManager[]>(`${environment.apiUrl}/users/promo_list/`, {})
  }

  public createClient(data: CreateClient): Observable<any>{
    return this.http.post<CreateClient>(`${environment.apiUrl}/users/client_api/`, {data}, {})
  }

  public changeBaseInfo(data: ChangeClientBaseInfo): Observable<any>{
    return this.http.patch<ChangeClientBaseInfo>(`${environment.apiUrl}/users/client_api/${data.id}/change_base_info/`, {data},  {})
  }

  public getSiteData(id:number): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/users/set_site_data/?id=${id}`,   {})
  }

}

