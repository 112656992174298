import {Component, OnInit} from '@angular/core';
import {ClientService} from "../../services/client.service";
import {Client} from "../../shared/interfaces/interfaces";
import {MatDialog} from "@angular/material/dialog";
import {Router} from "@angular/router";

@Component({
  selector: 'app-client-list',
  templateUrl: './client-list.component.html',
  styleUrls: ['./client-list.component.css']
})
export class ClientListComponent implements OnInit {

  public clientListsByManagerAndCategory: any = {};
  public id: number;


  constructor(public service: ClientService, public clientProfile: MatDialog, public router: Router) {
  }

  ngOnInit(): void {
    this._InitClientList()
  }

  private _InitClientList() {
    this.service.getClientList().subscribe(data => this.clientListsByManagerAndCategory = data)
  }

  public navigateToCreateClient() {
    this.router.navigate(["createclient"])
  }

  protected readonly Object = Object;
  protected readonly Array = Array;
}
