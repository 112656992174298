<div class="table-wrapper">
  <div class="table-higher-panel">
    <div><h1>Tables</h1></div>
    <div class="button-container">
      <button (click)="copy_tables()" class="copy-tables" [disabled]="!empty_data">
        Copy Tables
      </button>
      <button (click)="add_table()" class="add-button">
        <img src="assets/icons/plus.svg" class="plus-icon" alt="plus-icon">
        Add table
      </button>
    </div>
    <div>
      <mat-menu #setup="matMenu">
        <mat-button-toggle-group multiple [value]="['header']"
                                 #stickyHeaders="matButtonToggleGroup"
                                 class="example-sticky-toggle-group">
          <mat-button-toggle value="header"> Header</mat-button-toggle>
        </mat-button-toggle-group>
        <mat-button-toggle-group multiple [value]="['footer']"
                                 #stickyFooters="matButtonToggleGroup"
                                 class="example-sticky-toggle-group">
          <mat-button-toggle value="footer"> Footer</mat-button-toggle>
        </mat-button-toggle-group>
        <button mat-menu-item [matMenuTriggerFor]="Columns">StickyColumns</button>
      </mat-menu>

      <mat-menu #Columns="matMenu">
        <mat-button-toggle-group multiple [value]="['clientName','table_type', 'account_id']"
                                 #stickyColumns="matButtonToggleGroup"
                                 class="example-sticky-toggle-group">
          <mat-button-toggle value="clientName">Client Name</mat-button-toggle>
          <mat-button-toggle value="table_type">Table Type</mat-button-toggle>
          <mat-button-toggle value="account_id">Table Type</mat-button-toggle>
        </mat-button-toggle-group>
      </mat-menu>
    </div>
  </div>

  <div class="table-scroll-container">
    <ng-container *ngFor="let operator of operators">
      <div class="table-with-title-wrapper">
        <div class="table-title">
          <h3>{{dataSource[+operator][0].operatorName}}</h3>
        </div>
        <div class="table-container" #scrollContainer (scroll)="onScroll($event)">
          <mat-table [dataSource]="dataSource[+operator]">
            <ng-container *ngFor="let columnName of displayedColumns">
              <ng-container [matColumnDef]="columnName" [sticky]="isSticky(stickyColumns, columnName)">
                <mat-header-cell *matHeaderCellDef>{{getColumnHeader(columnName)}}</mat-header-cell>
                <mat-cell [ngClass]="{'day-off-cell': cell.isDayOff}" *matCellDef="let item" (dblclick)="cell.onDoubleClick()">
                  <app-table-cell #cell
                                  [cellData]="item[columnName]"
                                  [rowId]="item.id"
                                  [columnName]="columnName"
                                  [day]="+columnName"
                                  [isDefaultColumn]="isDefaultColumn(columnName)"
                                  [sum]="getTotalCost(dataSource[+operator], columnName, false)"
                                  (updateTable)="updateTable()">
                  </app-table-cell>
                </mat-cell>
                <mat-footer-cell *matFooterCellDef>{{getTotalCost(dataSource[+operator], columnName, true)}}</mat-footer-cell>
              </ng-container>
            </ng-container>

            <mat-header-row
              *matHeaderRowDef="displayedColumns; sticky: isSticky(stickyHeaders, 'header')"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns; last as isLast" [ngClass]="{'custom-background': isLast && isClientNameEmpty(row)}"></mat-row>
            <mat-footer-row
              *matFooterRowDef="displayedColumns; sticky: isSticky(stickyFooters, 'footer')"></mat-footer-row>
          </mat-table>
        </div>
      </div>

      <ng-container *ngIf="salaryData && salaryData[dataSource[+operator][0].operatorName]">
        <app-salary [data]="salaryData[dataSource[+operator][0].operatorName]" [operatorSalaryData]="operatorSalaryData[dataSource[+operator][0].operatorName]" [projectName]="project" [displayedColumns]="salaryColumns"></app-salary>
      </ng-container>
    </ng-container>
  </div>
</div>


