import {Component, EventEmitter, HostListener, Input, OnInit, Output, QueryList, ViewChildren,} from '@angular/core';
import {MatButtonToggleGroup} from '@angular/material/button-toggle';
import {CellWithData, OnlyFansTable} from "../../shared";
import {defaultColumns} from './constants/default-columns.constant';
import {map, Observable} from "rxjs";
import {MatDialog} from "@angular/material/dialog";
import {ConfirmationDialogComponent} from "./components/confirmation-dialog/confirmation-dialog.component";
import {TableDataService} from "../../services/table.service";

export const tableColumnNameMap: Record<string, string> = {
  "clientName": "Client Name",
  "table_type": "Table Type",
  "account_id": "Account Id"
}

export interface ITableDay {
  data: number;
  id: number;
  tableID?: number;
  day?: number;
  is_day_off?: boolean;
}

export type TablesPerOperator = Record<number, OnlyFansTable[]>;


/**
 * @title Flex-layout tables with toggle-able sticky headers, footers, and columns
 */
@Component({
  selector: 'app-table',
  styleUrls: ['table.component.css'],
  templateUrl: 'table.component.html',
})

export class TableComponent implements OnInit {

  @ViewChildren('scrollContainer') scrollContainers: QueryList<any>;
@HostListener('scroll', ['$event'])
onScroll(event: Event): void {
  const scrollContainer = event.target as HTMLElement;
  const scrollTop = scrollContainer.scrollTop;
  const scrollLeft = scrollContainer.scrollLeft;

  // Применяем прокрутку ко всем найденным элементам
  this.scrollContainers.forEach(container => {
    container.nativeElement.scrollTop = scrollTop;
    container.nativeElement.scrollLeft = scrollLeft;
  });

  // Применяем прокрутку к элементам в table-header-scroll-container
  const tableHeaderScrollContainer = document.querySelector('.table-header-scroll-container') as HTMLElement;
  if (tableHeaderScrollContainer) {
    tableHeaderScrollContainer.scrollTop = scrollTop;
    tableHeaderScrollContainer.scrollLeft = scrollLeft;
  }
}


  @Input()
  public displayedColumns: string[] = [];

  @Input()
  public defaultColumns: string[] = defaultColumns;

  @Input()
  public dataSource$: Observable<OnlyFansTable[]>;

  @Input()
  public project: string;

  public dataSource: TablesPerOperator;

  public multiplier: number;

  public empty_data: boolean;

  public isDayOff = false;

  @Input()
  public salaryColumns: string[];

  @Input()
  public month: number;

  @Input()
  public salaryData: any;

  @Input()
  public operatorSalaryData: any;

  @Input()
  public hasTwoFooters: boolean = false;

  @Output()
  public addTable: EventEmitter<any> = new EventEmitter<any>()

  @Output()
  public copyTables: EventEmitter<boolean> = new EventEmitter<boolean>();

  public get operators(): string[] {
    return Object.keys(this.dataSource || {});
  }

  constructor(
    public dialog: MatDialog,
    public tableData: TableDataService
  ) {
  }

  ngOnInit() {
    this.initTableData()
    this.set_multiplayer()
  }


  /** Whether the button toggle group contains the id as an active value. */
  isSticky(buttonToggleGroup: MatButtonToggleGroup, id: string) {
    return (buttonToggleGroup.value || []).indexOf(id) !== -1;
  }

  add_table() {
    this.addTable.emit(true);
  }

  public getColumnHeader(columnName: string): string {
    return tableColumnNameMap[columnName] ? tableColumnNameMap[columnName] : columnName;
  }

  public checkDayOff() {
    return this.isDayOff
  }

  public getTotalCost(dataSet: OnlyFansTable[], columnName: keyof OnlyFansTable, shouldMultiply: boolean = false) {
    if (!this.defaultColumns.includes(columnName as string)) {
      let counter = 0;
      (dataSet || []).forEach((dataSourceItem: OnlyFansTable) => {
        counter += (dataSourceItem[columnName] as unknown as CellWithData)?.data || 0;
      })
      const sum = +parseFloat(counter.toString())
      return (shouldMultiply ? sum * this.multiplier : sum).toFixed(2);
    }
    if (columnName as string == "Total") {
      let counter = 0;
      (dataSet || []).forEach((dataSourceItem: OnlyFansTable) => {
        counter += (dataSourceItem['Total'] as number) || 0;
      })
      const sum = +parseFloat(counter.toString())
      return (shouldMultiply ? sum * this.multiplier : sum).toFixed(2);
    } else if (columnName as string == "TotalDollars") {
      let counter = 0;
      (dataSet || []).forEach((dataSourceItem: OnlyFansTable) => {
        counter += (dataSourceItem['TotalDollars'] as number) || 0;
      })
      const sum = +parseFloat(counter.toString())
      return sum.toFixed(2);
    } else {
      return "";
    }
  }

  public isDefaultColumn(columnName: string): boolean {
    return this.defaultColumns.includes(columnName)
  }

  public updateTable() {
    this.initTableData();
    this.checkDayOff();
  }

  public initTableData() {
    setTimeout(() => {
      this.dataSource$
        .pipe(
          map((data: OnlyFansTable[]) => {
            if (data.length === 0) {
              this.empty_data = true;
              return {};
            }
            data.map((item: OnlyFansTable) => {
              item.clientName = `${item.clientName} ${item.clientSurname}`;
            });
            const preparationObj: TablesPerOperator = {};
            data.forEach((item: OnlyFansTable) => {
              preparationObj[item.operator] = [];
            });
            return data.reduce((result: TablesPerOperator, currentItem: OnlyFansTable) => ({
              ...result,
              [currentItem.operator]: [...result[currentItem.operator], currentItem]
            }), preparationObj);
          }),
          map((tables: TablesPerOperator) => {
            if (this.hasTwoFooters && Object.keys(tables).length > 0) {
              const keys: number[] = Object.keys(tables) as unknown as number[]
              keys.forEach((key: number) => {
                tables[key].push({
                  id: null,
                  clientSurname: 'privet',
                  clientName: '',
                  tableType: null,
                  tabledata_set: [],
                  operator: null,
                  operatorName: null,
                })
              })
            }
            return tables
          })
        )
        .subscribe((data: TablesPerOperator) => {
          this.dataSource = data;
        });
    }, 1000)
  }

  isClientNameEmpty(row: any): boolean {
    return !row.clientName || row.clientName.trim() === '';
  }


  public set_multiplayer() {
    if (this.hasTwoFooters) {
      this.multiplier = 1.5
    } else {
      this.multiplier = 1
    }
  }

  public copy_tables() {

    const dialogRef = this.dialog.open(ConfirmationDialogComponent);

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.copyTables.emit(true);
      } else {
      }
    });
  }

}
