import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";

export interface PopupData {
  message: string;
  popupType: boolean;
}

@Component({
  selector: 'app-popup-service',
  templateUrl: './popup-service.component.html',
  styleUrls: ['./popup-service.component.css']
})

export class PopupServiceComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: PopupData
  ) {
  }
}
