import {Component, Input, OnInit, SimpleChanges} from '@angular/core';
import {tableColumnNameMap} from "../table/table.component";
import {Month, monthDaysMap, months} from "../../shared/interfaces/interfaces";
import {map, Observable} from "rxjs";
import {MatButtonToggleGroup} from "@angular/material/button-toggle";

@Component({
  selector: 'app-table-header',
  templateUrl: './table-header.component.html',
  styleUrls: ['./table-header.component.css']
})

export class TableHeaderComponent implements OnInit {

  @Input()
  data: any


  @Input()
  totalByMonth: number

  public months = months;

  displayedColumns: string[]

  public dataSource: Observable<any>;

  @Input()
  currentMonth: Month

  @Input() dataSource$: any;

  ngOnChanges(changes: SimpleChanges) {
    if (changes['dataSource$']) {
      this.dataSource = this.dataSource$
    }
  }


  ngOnInit() {
    this._initColumns()
  }

  private _initColumns() {
    const days: any = Array.from({length: 31}, (_, i) => (i + 1).toString())
    this.displayedColumns = ['Site', ...days, 'Total'];
  }

  public getColumnHeader(columnName: string): string {
    return tableColumnNameMap[columnName] ? tableColumnNameMap[columnName] : columnName;
  }

  isSticky(buttonToggleGroup: MatButtonToggleGroup, id: string) {
    return (buttonToggleGroup.value || []).indexOf(id) !== -1;
  }
}


