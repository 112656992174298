import {Component, Inject} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {
  ClientProfile,
  CreateOnlyFansTable,
  OperatorAndClientList,
  OperatorData,
  selectorMonths
} from "../../shared/interfaces/interfaces";
import {ISelectorData, ISelectorMonth, TableType} from "../create-table/create-table.component";
import {ISelectorOption} from "../../shared/components/selector/selector.component";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {TableDataService} from "../../services/table.service";
import {YearService} from "../year-service/year-service";

@Component({
  selector: 'app-create-onlyfans-table',
  templateUrl: './create-onlyfans-table.component.html',
  styleUrls: ['./create-onlyfans-table.component.css']
})
export class CreateOnlyfansTableComponent {

  public createTableForm: FormGroup<CreateOnlyFansTable>;
  public operators: OperatorData[];
  public clients: ClientProfile[];
  public selectorMonths: ISelectorMonth[];
  public selectorsData: ISelectorData[];
  public tableTypeOptions: ISelectorOption<TableType>[];
  public readonly title: string = "Create new table";
  public readonly buttonText: string = "Create table";

  constructor(
    public dialogRef: MatDialogRef<CreateOnlyfansTableComponent>,
    public service: TableDataService,
    public yearService: YearService,
    @Inject(MAT_DIALOG_DATA) public data: CreateOnlyFansTable
  ) {
  }

  ngOnInit() {
    this._initCreateTableForm();
    this._getUserAndClientInfo();
  }

  private _initCreateTableForm() {
    this.createTableForm = new FormGroup<CreateOnlyFansTable>(
      <CreateOnlyFansTable>{
        client: new FormControl<number>(0, Validators.required),
        month: new FormControl<number>(0, Validators.required),
        operator: new FormControl<number>(0, Validators.required),
        tableType: new FormControl<string>("", Validators.required),
        year: new FormControl<number>(this.yearService.currentYear),
        project: new FormControl<string>("OnlyFans", Validators.required)
      }
    )
  }


  private _getUserAndClientInfo() {
    this.service.getUserClientList("OnlyFans")
      .subscribe(
        (data: OperatorAndClientList) => {
          this.operators = data.operator;
          this.clients = data.client;
          this._initSelectors()
        }
      );
  }

  onNoClick() {
    let data = this.createTableForm.value
    return this.service.createNewTable(data).subscribe(
      () => this.dialogRef.close()
    )
  }

  private _initSelectorMonths() {
    this.selectorMonths = selectorMonths
  }

  private _initSelectors() {
    this._initSelectorMonths();
    const clientOptions: ISelectorOption[] = [];
    const operatorOptions: ISelectorOption[] = [];
    this.tableTypeOptions = [TableType.OP, TableType.FP, TableType.PP]
      .map((tableType: TableType): ISelectorOption<TableType> => ({
        label: tableType,
        value: tableType
      }));
    this.clients.forEach((client: ClientProfile) => {
      clientOptions.push({
        value: client.id,
        label: client.full_name
      })
    });

    this.operators.forEach((operator: OperatorData) => {
      operatorOptions.push({
        value: operator.id,
        label: operator.last_name + ' ' + operator.first_name
      })
    })
    this.selectorsData = [
      {
        label: "Choose client",
        options: clientOptions,
        formControl: this.createTableForm.get("client") as FormControl<any>
      },
      {
        label: "Choose operator",
        options: operatorOptions,
        formControl: this.createTableForm.get("operator") as FormControl<any>
      },
      {
        label: "Choose month",
        options: this.selectorMonths,
        formControl: this.createTableForm.get("month") as FormControl<any>
      },
      {
        label: "Table type",
        options: this.tableTypeOptions,
        formControl: this.createTableForm.get("tableType") as FormControl<any>
      }
    ]
  }


}
