<ng-container>
  <div *ngIf="!isInputCell else nonDefaultCell">
     {{cellData}}
  </div>

  <ng-template #nonDefaultCell>
    <div *ngIf="!showInput else inputTemplate">
      {{cellData || " "}}
    </div>
  </ng-template>

  <ng-template #inputTemplate>
    <input #cellInput (keyup.enter)="formControl.valid && (onEnter())" (focusout)="onClickOutside()" [formControl]="formControl">
  </ng-template>
</ng-container>
