import {Component, Inject, OnInit} from '@angular/core';
import {Subscription} from "rxjs";
import {AuthService} from "../../services/authentification.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {LoginData} from "../../shared/interfaces/interfaces";

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.css'],
})
export class LoginPageComponent implements OnInit {

  private aSub: Subscription

  constructor(
    public dialogRef: MatDialogRef<LoginPageComponent>,
    private auth: AuthService,
    @Inject(MAT_DIALOG_DATA) public data: LoginData) {
  }


  onNoClick(): void {
    this.aSub = this.auth.login({username: this.data.login, password: this.data.password}).subscribe(() => {
      this.dialogRef.close()
    })
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    if (this.aSub) {
      this.aSub.unsubscribe()
    }
  }

}
