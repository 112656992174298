import {Component, Input} from '@angular/core';
import {FormControl} from "@angular/forms";
import {InputClass} from "./input-class.enum";

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.css']
})
export class InputComponent {
  @Input()
  public control: FormControl<string | null>;

  @Input()
  public label: string;

  @Input()
  public placeholder: string = "";

  @Input()
  public isDateInput: boolean = false

  public get inputClasses(): InputClass{
    if (this.control.untouched) {
      return InputClass.DEFAULT
    } else if (this.control.invalid) {
      return InputClass.ERROR
    } else {
      return InputClass.ACTIVE
    }
  }
}
