import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {AuthService} from "./authentification.service";
import {ITableDay} from "../components/table/table.component";
import { environment } from 'src/env/environment';


@Injectable()
export class TableCellService{

  constructor(private http: HttpClient, private token: AuthService) {}

  public patch_table_cell(id:number, data:number, day_off: boolean = false){
    return this.http.patch(
      `${environment.apiUrl}/core/table_data/${id}/`, {data: data, is_day_off: day_off},{}
    )
      .subscribe((data) => console.log(data)
      )

  }

  public create_new_table_cell(tableID: number, day: number, data: number){
    const cellData = {data: data, tableId: tableID, date: day}
    return this.http.post<ITableDay>(
      `${environment.apiUrl}/core/table_data/`, cellData, {}
    )
  }

  public create_new_table_cell_with_dayoff(tableID: number, day: number, day_off: boolean){
    const cellData = {tableId: tableID, date: day, is_day_off: day_off}
    return this.http.post<ITableDay>(
      `${environment.apiUrl}/core/table_data/`, cellData, {}
    )
  }



}
