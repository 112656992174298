<div class="salary-header">
  <p>Salary </p>
  <div class="salary-header-info">
    <p>
  <ng-container *ngIf="data && data.length > 0">
    {{ data[0].full_name }} / {{ projectName }} / {{ operatorSalaryData && operatorSalaryData.length > 0 ? (formatDate(operatorSalaryData[0].date) ) : 'No date available' }}

  </ng-container>
  <ng-container *ngIf="!data || data.length === 0">
    No data available
  </ng-container>
</p>
    <div class="input-field">
      <p class="comment" *ngIf="!editing" (dblclick)="startEditing()">{{ text }}</p>
      <input *ngIf="editing" #inputField [value]="text" (blur)="stopEditing(inputField.value)" (keyup.enter)="stopEditing(inputField.value)">
    </div>
    <div class="selector">
      <select (change)="onStatusChange($event)" [(ngModel)]="status">
        <option [value]="'Paying'" [selected]="status === 'Paying'">Paying</option>
        <option [value]="'Not Paying'" [selected]="status === 'Not Paying'">Not paying</option>
        <option [value]="'Paid'" [selected]="status === 'Paid'">Paid</option>
      </select>
    </div>

  </div>
</div>
<div class="grid-container">
  <div class="table-container" [ngClass]="{ 'fansly-style': projectName === 'Fansly' || projectName === 'Fancentro' || projectName === 'OnlyFans' }">
    <table mat-table [dataSource]="data">
      <ng-container *ngFor="let column of displayedColumns" [matColumnDef]="column">
        <mat-header-cell *matHeaderCellDef>{{ getColumnHeader(column) }}</mat-header-cell>
        <mat-cell *matCellDef="let element" (dblclick)="cell.onDoubleClick()">
          <app-salary-cell #cell
                           [cellData]="element[column]"
                           [tableData]="element"
                           [tableId]="element['id']"
                           [isInputCell]="inputColumn(column)"
                           [columnName]=column
                           (updateTable)="updateTable()"
                           (newData)="handleNewData($event)">
          </app-salary-cell>
        </mat-cell>
        <mat-footer-cell *matFooterCellDef>{{ getColumnTotal(column) }}</mat-footer-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
      <mat-footer-row *matFooterRowDef="displayedColumns"></mat-footer-row>
    </table>
  </div>
  <!---->
  <div class="operator-salary-container">
    <app-operator-salary [data]="operatorSalaryData"></app-operator-salary>
  </div>
</div>

