import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable, tap} from "rxjs";
import {Token} from "../main-components/header/interfaces/header-item.interface";
import {environment} from 'src/env/environment';
import {switchMap} from "rxjs/operators";
import {User} from "../shared";


@Injectable()

export class AuthService {
  constructor(private http: HttpClient) {
  }

  public login(user: User): Observable<any> {
    return this.http.post<Token>(`${environment.apiUrl}/api/token/`, user)
      .pipe(
        switchMap(
          (token: Token) => {
            localStorage.setItem('access-token', token.access)
            localStorage.setItem('refresh-token', token.refresh)
            return this.getPerms()
          }
        ),
        tap(
          data => localStorage.setItem("perms", data)
        )
      )
  }

  public logout() {
    localStorage.clear()
  }

  public isAuthenticated(): boolean {
    return !!localStorage.getItem('refresh-token')
  }


  public saveRefreshToken(token: string): void {
    localStorage.removeItem('refresh-token');
    localStorage.setItem('refresh-token', token);
  }

  public saveAccessToken(token: string) {
    localStorage.setItem('access-token', token)
  }

  public getRefreshToken(): string | null {
    return localStorage.getItem('refresh-token');
  }

  public getAccessToken(token: string) {
    return this.http.post<Token>(`${environment.apiUrl}/api/token/refresh/`, {refresh: token})
  }

  public getPerms() {
    return this.http.get<any>(`${environment.apiUrl}/users/user_perms/`)
  }
}
