import {NgModule} from "@angular/core";
import {RouterModule, Routes} from "@angular/router";
import {RegistrationComponent} from "./components/registration/registration.component";
import {MainComponent} from "./components/main/main.component";
import {ClientListComponent} from "./components/client-list/client-list.component";
import {ClientProfileComponent} from "./components/client-profile/client-profile.component";
import {LoginComponent} from "./components/login/login.component";
import {AuthGuard} from "./guards/auth.guard";
import {CreateClientComponent} from "./components/create-client/create-client.component";
import {GoldenBrideComponent} from "./components/golden-bride/golden-bride.component";
import {ClientProfileResolver} from "./components/client-profile/client-profile.resolver";
import {CharmDateComponent} from "./components/charm-date/charm-date.component";
import {DateMeComponent} from "./components/date-me/date-me.component";
import {JumpForLoveComponent} from "./components/jump-for-love/jump-for-love.component";
import {RomanceCompassComponent} from "./components/romance-compass/romance-compass.component";
import {AnastasiaDatingComponent} from "./components/anastasia-dating/anastasia-dating.component";
import {PermissionGuard} from "./guards/perms.guard";
import {OnlyfansComponent} from "./components/onlyfans/onlyfans.component";
import {FanslyComponent} from "./components/fansly/fansly.component";
import {FancentroComponent} from "./components/fancentro/fancentro.component";
import {ClientSalaryComponent} from "./components/client-salary/client-salary.component";

const routes: Routes = [
  {
    path: '',
    redirectTo: "login",
    pathMatch: "full"
  },
  {
    path: 'workpage',
    component: MainComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'login',
    component:
    LoginComponent
  },
  {
    path: 'register',
    component: RegistrationComponent
  },
  {
    path: 'clientlist',
    component: ClientListComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'clientprofile/:id',
    component: ClientProfileComponent,
    canActivate: [AuthGuard],
    resolve: {
      clientData: ClientProfileResolver
    }
  },
  {
    path: 'createclient',
    component: CreateClientComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'goldenbride',
    component: GoldenBrideComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      requiredPermission: ['[GoldenBride]Operator', '[GoldenBride]Project Manager']
    }
  },
  {
    path: 'charmdate',
    component: CharmDateComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      requiredPermission: ['[CharmDate]Operator', '[CharmDate]Project Manager']
    }
  },
  {
    path: 'dateme',
    component: DateMeComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      requiredPermission: ['[DateMe]Operator', '[DateMe]Project Manager']
    }
  },
  {
    path: 'jumpforlove',
    component: JumpForLoveComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      requiredPermission: ['[JumpForLove]Operator', '[JumpForLove]Project Manager']
    }
  },
  {
    path: 'romancecompass',
    component: RomanceCompassComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      requiredPermission: ['[RomanceCompass]Operator', '[RomanceCompass]Project Manager']
    }
  },
  {
    path: 'anastasiadating',
    component: AnastasiaDatingComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      requiredPermission: ['[AnastasiaDating]Operator', '[AnastasiaDating]Project Manager']
    }


  },
  {
    path: 'onlyfans',
    component: OnlyfansComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      requiredPermission: ['[OnlyFans]Operator', '[OnlyFans]Project Manager']
    }
  },
  {
    path: 'fansly',
    component: FanslyComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      requiredPermission: ['[Fansly]Operator', '[Fansly]Project Manager']
    }
  },
  {
    path: 'fancentro',
    component: FancentroComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      requiredPermission: ['[Fancentro]Operator', '[Fancentro]Project Manager']
    }
  },
  {
    path: 'client_salary',
    component: ClientSalaryComponent,
    canActivate: [AuthGuard],
    data: {
      // requiredPermission: ['[Fancentro]Operator', '[Fancentro]Project Manager'] тут надо подумать
    }
  },

]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {

}

