import { Component } from '@angular/core';
import {MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-delete-row-popup',
  templateUrl: './delete-row-popup.component.html',
  styleUrls: ['./delete-row-popup.component.css']
})
export class DeleteRowPopupComponent {

  constructor(public dialogRef: MatDialogRef<DeleteRowPopupComponent>) {}

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  onYesClick(): void {
    this.dialogRef.close(true);
  }

}
