import {Component, TemplateRef, ViewChild} from '@angular/core';
import {ITabInfo, Month, months} from "../../shared";
import {MatTabChangeEvent} from "@angular/material/tabs";
import {ClientSalaryService} from "../../services/client-salary.service";
import {Observable} from "rxjs";
import {YearService} from "../year-service/year-service";


export const clientSalaryColumnNameMap: Record<string, string> = {
  "full_name": "Client Name",
  "anastasia_dating_total": "AD",
  "dating_total": "D",
  "charm_date_total": "CD",
  "golden_bride_total": "GB",
  "date_me_total": "DM",
  "jump_for_love_total": "J4L",
  "romance_compass_total": "RC",
  "fansly_total" : "Fansly",
  "fancentro_total" : "Fancentro",
  "total_by_all_projects": "$",
  "client_total_by_all_projects" : "10% $"
}

@Component({
  selector: 'app-client-salary',
  templateUrl: './client-salary.component.html',
  styleUrls: ['./client-salary.component.css']
})
export class ClientSalaryComponent {

  public currentMonth: Month;

  public selectedMonthIndex: number = 0;

  public tabs: ITabInfo[];

  public data: any;

  public months = months;

  public defaultColumns = ['full_name', 'anastasia_dating_total', 'dating_total', 'charm_date_total',
    'date_me_total', 'romance_compass_total', 'jump_for_love_total', 'golden_bride_total',
    'fansly_total','fancentro_total','total_by_all_projects', 'client_total_by_all_projects', 'Paid']

  public dataSource: Observable<any>;

  public currentYear: number = this.yearService.currentYear

  @ViewChild("dataTableTemplate", {static: true})
  public dataTableTemplate: TemplateRef<any>;

  constructor(
    public clientSalaryData: ClientSalaryService,
    public yearService: YearService
  ) {
  }


  public ngOnInit() {
    this._initTabs()
    this._initActiveTab()
    this._initDataSource()
  }

  onTabChange($event: MatTabChangeEvent) {
    this.currentMonth = $event.tab.textLabel as Month;
    this._initDataSource();
  }

  private _initTabs(): void {
    this.tabs = [];
    this.months.forEach((month: Month) => {
      this.tabs.push({
        label: month,
        content: this.dataTableTemplate
      })
    })
  }

  private _initActiveTab() {
    this.selectedMonthIndex = new Date().getMonth();
    this.currentMonth = months[this.selectedMonthIndex];
  }

  private _initDataSource(): void {
    this.clientSalaryData.getClientSalaryData(this.months.indexOf(this.currentMonth) + 1, this.currentYear).subscribe(
    data => this.data = data
    )
  }

  public getColumnHeader(columnName: string): string {
    return clientSalaryColumnNameMap[columnName] ? clientSalaryColumnNameMap[columnName] : columnName;
  }

  public getColumnTotal(column: string, manager: string, category: string, tables: string): number | string {
    let total = 0;
    if (!Array.isArray(this.data)) {
    this.data = [this.data]; // Преобразуем в массив, если не является
  }
    if (column == 'full_name') {
      return ' '
    } else {
      for (const element of this.data[manager][category][tables]) {
        total += +element[column] || 0;
      }
      return total !== 0 ? total.toFixed(2) : '0.00';
    }
  }

  protected readonly Object = Object;
}
