import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {AuthService} from "./authentification.service";
import {Observable} from "rxjs";
import {CreateAnastasiaDatingTable, CreateOnlyfansTable, OperatorAndClientList} from "../shared/interfaces/interfaces";
import {ɵFormGroupValue, ɵTypedOrUntyped} from "@angular/forms";
import {environment} from 'src/env/environment';

@Injectable()
export class TableDataService {

  constructor(private http: HttpClient) {
  }

  public getTableData(month: number, year: number, project: string): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/core/table_view/?month=${month}&year=${year}&project=${project}`, {})
  }

  public createNewTable(data: ɵTypedOrUntyped<CreateOnlyfansTable | CreateAnastasiaDatingTable, ɵFormGroupValue<CreateAnastasiaDatingTable>, any>): Observable<CreateAnastasiaDatingTable> {
    return this.http.post<CreateAnastasiaDatingTable>(`${environment.apiUrl}/core/table_view/`, data, {})
  }

  public getUserClientList(project: string) {
    return this.http.get<OperatorAndClientList>(`${environment.apiUrl}/users/userinfo/?project=${project}`,  {})
  }

  public copyTables(project: string, month:number, year: number){
    return this.http.post<any>(`${environment.apiUrl}/core/table_view/create_many_tables/?project=${project}&month=${month}&year=${year}`, {})
  }

  public deleteTable(id:number){
    return this.http.delete(`${environment.apiUrl}/core/table_view/${id}/`, {})
  }

}


