import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppComponent} from './app.component';
import {HeaderComponent} from "./main-components/header/header.component";
import {LoginPageComponent} from "./components/login-page/login-page.component";
import {TableComponent} from "./components/table/table.component";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {MatTableModule} from "@angular/material/table";
import {MatButtonToggleModule} from "@angular/material/button-toggle";
import {MatButtonModule} from "@angular/material/button";
import {HttpClientModule} from "@angular/common/http";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {AppRoutingModule} from "./app-routing.module";
import {AuthService} from "./services/authentification.service";
import {TableCellService} from "./services/tabledata.service";
import {TableCellComponent} from './components/table/components/table-celll/table-cell.component';
import {MatDialogModule} from "@angular/material/dialog";
import {MatInputModule} from "@angular/material/input";
import {MatToolbarModule} from "@angular/material/toolbar";
import {RegistrationComponent} from './components/registration/registration.component';
import {MatCardModule} from "@angular/material/card";
import {RegistrationService} from "./services/registration.service";
import {MainComponent} from './components/main/main.component';
import {MatTabsModule} from "@angular/material/tabs";
import {MatExpansionModule} from "@angular/material/expansion";
import {MatMenuModule} from "@angular/material/menu";
import { CreateTableComponent } from './components/create-table/create-table.component';
import {MatSelectModule} from "@angular/material/select";
import {TableDataService} from "./services/table.service";
import { ClientListComponent } from './components/client-list/client-list.component';
import {MatGridListModule} from "@angular/material/grid-list";
import {MatChipsModule} from "@angular/material/chips";
import {ClientService} from "./services/client.service";
import { ClientProfileComponent } from './components/client-profile/client-profile.component';
import {MatIconModule} from "@angular/material/icon";
import { PopupServiceComponent } from './components/popup-service/popup-service.component';
import { LoginComponent } from './components/login/login.component';
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import { CreateClientComponent } from './components/create-client/create-client.component';
import {MatDatepickerModule} from "@angular/material/datepicker";
import {DatepickerComponent, MY_DATE_FORMATS} from './components/datepicker/datepicker.component';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { DateMaskDirective } from './directives/date-mask.directive';
import { GoldenBrideComponent } from './components/golden-bride/golden-bride.component';
import { CreateGoldenBrideTableComponent } from './components/create-golden-bride-table/create-golden-bride-table.component';
import {ClientProfileResolver} from "./components/client-profile/client-profile.resolver";
import {AuthInterceptor, authInterceptorProviders} from "./services/helpers/auth.intercepter";
import { CharmDateComponent } from './components/charm-date/charm-date.component';
import { CharmDateCreateTableComponent } from './components/charm-date-create-table/charm-date-create-table.component';
import { DateMeComponent } from './components/date-me/date-me.component';
import { CreateDateMeTableComponent } from './components/create-date-me-table/create-date-me-table.component';
import { JumpForLoveComponent } from './components/jump-for-love/jump-for-love.component';
import { CreateJumpForLoveTableComponent } from './components/create-jump-for-love-table/create-jump-for-love-table.component';
import { RomanceCompassComponent } from './components/romance-compass/romance-compass.component';
import { CreateRomanceCompassTableComponent } from './components/create-romance-compass-table/create-romance-compass-table.component';
import { AnastasiaDatingComponent } from './components/anastasia-dating/anastasia-dating.component';
import { CreateAnastasiaDatingTableComponent } from './components/create-anastasia-dating-table/create-anastasia-dating-table.component';
import { TableHeaderComponent } from './components/table-header/table-header.component';
import {TableHeaderService} from "./services/table-header.service";
import { OnlyfansComponent } from './components/onlyfans/onlyfans.component';
import { CreateOnlyfansTableComponent } from './components/create-onlyfans-table/create-onlyfans-table.component';
import { FanslyComponent } from './components/fansly/fansly.component';
import { CreateFanslyTableComponent } from './components/create-fansly-table/create-fansly-table.component';
import { FancentroComponent } from './components/fancentro/fancentro.component';
import { CreateFancentroTableComponent } from './components/create-fancentro-table/create-fancentro-table.component';
import { SalaryComponent } from './components/salary/salary.component';
import {SalaryService} from "./services/salary.service";
import { SalaryCellComponent } from './components/salary/components/salary-cell/salary-cell.component';
import {YearService} from "./components/year-service/year-service";
import {OperatorSalaryComponent} from "./components/salary/operator-salary/operator-salary.component";
import {OperatorSalaryCellComponent} from "./components/salary/operator-salary/components/operator-salary-cell/operator-salary-cell.component";
import {MainService} from "./services/main.service";
import { TotalByProjectAndMonthComponent } from './components/total-by-project-and-month/total-by-project-and-month.component';
import {ConfirmationDialogComponent} from "./components/table/components/confirmation-dialog/confirmation-dialog.component";
import {
  BoxComponent,
  InputComponent,
  SelectorComponent
} from "./shared";
import { ClientSalaryComponent } from './components/client-salary/client-salary.component';
import {ClientSalaryService} from "./services/client-salary.service";


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    LoginPageComponent,
    TableComponent,
    TableCellComponent,
    RegistrationComponent,
    MainComponent,
    CreateTableComponent,
    ClientListComponent,
    ClientProfileComponent,
    PopupServiceComponent,
    LoginComponent,
    CreateClientComponent,
    GoldenBrideComponent,
    CreateGoldenBrideTableComponent,
    InputComponent,
    SelectorComponent,
    DateMaskDirective,
    BoxComponent,
    CharmDateComponent,
    CharmDateCreateTableComponent,
    DateMeComponent,
    CreateDateMeTableComponent,
    JumpForLoveComponent,
    CreateJumpForLoveTableComponent,
    RomanceCompassComponent,
    CreateRomanceCompassTableComponent,
    AnastasiaDatingComponent,
    CreateAnastasiaDatingTableComponent,
    TableHeaderComponent,
    OnlyfansComponent,
    CreateOnlyfansTableComponent,
    FanslyComponent,
    CreateFanslyTableComponent,
    FancentroComponent,
    CreateFancentroTableComponent,
    SalaryComponent,
    SalaryCellComponent,
    OperatorSalaryComponent,
    OperatorSalaryCellComponent,
    TotalByProjectAndMonthComponent,
    ConfirmationDialogComponent,
    ClientSalaryComponent,

  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MatTableModule,
    MatButtonToggleModule,
    MatButtonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    MatDialogModule,
    MatInputModule,
    MatToolbarModule,
    MatCardModule,
    MatTabsModule,
    MatExpansionModule,
    MatMenuModule,
    MatSelectModule,
    MatGridListModule,
    MatChipsModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatDatepickerModule,
    DatepickerComponent
  ],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS },
    AuthService,
    TableDataService,
    RegistrationService,
    TableCellService,
    ClientService,
    AuthInterceptor,
    authInterceptorProviders,
    ClientProfileResolver,
    TableHeaderService,
    SalaryService,
    YearService,
    MainService,
    ClientSalaryService,
  ],
  exports: [
    DatepickerComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
