<div class="mat-card-wrapper">
  <app-box [title]="title" [buttonText]="buttonText" (buttonClick)="registerUser(registrationForm)">
    <form class="my-form" [formGroup]="registrationForm">
      <ng-container *ngFor="let item of inputItems">
        <app-input [label]="item.label" [placeholder]="item.placeholder" [control]="item.control" [isDateInput]="!!item.isDateInput"></app-input>
      </ng-container>
    </form>
    <p>Already have an account? <a class="link" (click)="navigateToLogin()">Log in</a></p>
  </app-box>
</div>
