<app-header></app-header>


<mat-tab-group (selectedTabChange)="onTabChange($event)" [selectedIndex]="selectedMonthIndex">
  <ng-container *ngFor="let tab of tabs">
    <mat-tab [label]="tab.label">
      <ng-container [ngTemplateOutlet]="dataTableTemplate"
                    [ngTemplateOutletContext]="{'label': tab.label}"></ng-container>
    </mat-tab>
  </ng-container>
</mat-tab-group>


<ng-template #dataTableTemplate let-label="label">

  <app-table-header [dataSource$]="headersData$" [currentMonth]="currentMonth" [totalByMonth]="totalSalaryByMonth" ></app-table-header>

  <app-table *ngIf="label === currentMonth" [defaultColumns]="defaultColumns" [displayedColumns]="displayedColumns"
             [dataSource$]="dataSource$" [salaryData]="salaryData$" [salaryColumns]="salaryColumns"
             [hasTwoFooters]="false" [operatorSalaryData]="operatorSalaryData" [project]="projectName"
             (addTable)="addTable()" (copyTables)="copy_table()"></app-table>
</ng-template>


