import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {AuthService} from "../../services/authentification.service";
import {Subscription} from "rxjs";
import {PopupServiceComponent} from "../popup-service/popup-service.component";
import {MatDialog} from "@angular/material/dialog";
import {InputItem, User} from "../../shared";
import {FormControl, FormGroup, Validators} from "@angular/forms";

export interface ILoginForm {
  username: FormControl<string>;
  password: FormControl<string>;
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  public readonly title: string = "Login";
  public readonly buttonText: string = "Login";
  public inputItems: InputItem[];
  public loginForm: FormGroup<ILoginForm>;
  constructor(private router: Router, private auth: AuthService, private dialog: MatDialog) {
    if (auth.isAuthenticated()){
      this.router.navigate(['workpage'])
    }
  }

  public ngOnInit() {
    this._initLoginForm();
    this._initInputItems()
  }

  private aSub: Subscription

  onClick(): void {
    this.aSub = this.auth.login(this.loginForm.value as User).subscribe(()=> {
      this.router.navigate(["workpage"])
  },
      error => {
      console.error('Произошла ошибка:', error, );
        this.showPopup(error, false)
      })
  }

  public getLoginFormControl(controlName: keyof ILoginForm): FormControl<string> {
    return this.loginForm.get(controlName) as FormControl<string>;
  }

  navigateToRegistration(){
    this.router.navigate(["register"])
  }

  public showPopup(message: string, popuptype: boolean){
    const dialogRef = this.dialog.open(PopupServiceComponent, {
      width: '250px',
      data: {message: message, popupType: popuptype},

    });
  }

  private _initLoginForm(): void {
    this.loginForm = new FormGroup<ILoginForm>({
      username: new FormControl<string>("", {
        validators: [Validators.required, Validators.minLength(3)],
        nonNullable: true,
      }),
      password: new FormControl<string>("", {
        validators: [Validators.required, Validators.minLength(3)],
        nonNullable: true,
      }),
    });
  }

  private _initInputItems(): void {
    this.inputItems = [
      {
        control: this.getLoginFormControl("username"),
        placeholder: "John Smith",
        label: "Login"
      },
      {
        control: this.getLoginFormControl("password"),
        placeholder: "*****",
        label: "Password"
      }
    ];
  }

}

