import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-box',
  templateUrl: './box.component.html',
  styleUrls: ['./box.component.css']
})
export class BoxComponent {

  @Input()
  public title: string;

  @Input()
  public buttonText: string;

  @Output()
  public buttonClick: EventEmitter<boolean> = new EventEmitter<boolean>()

  public onButtonClick() {
    this.buttonClick.emit(true)
  }
}
