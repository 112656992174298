<label [innerHTML]="label"></label>
<div class="custom-select">
  <div class="selected-item" (click)="toggleOptions()">
    {{ selectedItem }}
    <div class="arrow" [ngClass]="{ 'open-arrow': isOpen }">
      <span>&#9660;</span>
    </div>
  </div>
  <div class="options" [ngClass]="{ 'open': isOpen }">
    <div class="option" *ngFor="let option of options" (click)="selectOption(option)">
      {{ option.label }}
    </div>
  </div>
</div>
