import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {
  ClientProfile,
  CreateOnlyfansTable, OperatorAndClientList,
  OperatorData,
  selectorMonths,
} from "../../shared/interfaces/interfaces";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {TableDataService} from "../../services/table.service";
import {ISelectorOption} from "../../shared/components/selector/selector.component";

export interface ISelectorMonth {
  value: number;
  label: string;
}

export interface ISelectorData {
  options: ISelectorOption<number | TableType>[];
  formControl: FormControl<any>;
  label: string;
}

export const enum TableType {
  OP = "OP",
  FP = "FP",
  PP = "PP",
  AD = "AD",
  D = "D"
}

@Component({
  selector: 'app-create-table',
  templateUrl: './create-table.component.html',
  styleUrls: ['./create-table.component.css']
})
export class CreateTableComponent implements OnInit {
  public createTableForm: FormGroup<CreateOnlyfansTable>;
  public operators: OperatorData[];
  public clients: ClientProfile[];
  public selectorMonths: ISelectorMonth[];
  public selectorsData: ISelectorData[];
  public tableTypeOptions: ISelectorOption<TableType>[];
  public readonly title: string = "Crate new table";
  public readonly buttonText: string = "Create table";

  constructor(
    public dialogRef: MatDialogRef<CreateTableComponent>,
    public service: TableDataService,
    @Inject(MAT_DIALOG_DATA) public data: CreateOnlyfansTable
  ) {
  }

  ngOnInit() {
    this._initCreateTableForm();
    this._getUserAndClientInfo();
  }

  private _initCreateTableForm() {
    this.createTableForm = new FormGroup<CreateOnlyfansTable>(
      <CreateOnlyfansTable>{
        client: new FormControl<number | null>(null, Validators.required),
        month: new FormControl<number>(0, Validators.required),
        operator: new FormControl<number | null>(3, Validators.required),
        tableType: new FormControl<TableType | null>(null, Validators.required),
        project: new FormControl<string>('OnlyFans')
      }
    )
  }


  private _getUserAndClientInfo() {
    this.service.getUserClientList("OnlyFans")
      .subscribe(
        (data: OperatorAndClientList) => {
          this.operators = data.operator;
          this.clients = data.client;
          this._initSelectors();
        }
      );
  }

  onNoClick() {
    let data = this.createTableForm.value
    return this.service.createNewTable(data).subscribe(
      () => this.dialogRef.close()
    )
  }

  private _initSelectorMonths() {
    this.selectorMonths = selectorMonths;
  }

  private _initSelectors() {
    this._initSelectorMonths();
    const clientOptions: ISelectorOption[] = [];
    const operatorOptions: ISelectorOption[] = [];
    this.tableTypeOptions = [TableType.OP, TableType.FP, TableType.PP]
      .map((tableType: TableType): ISelectorOption<TableType> => ({
        label: tableType,
        value: tableType
      }));

    this.clients.forEach((client: ClientProfile) => {
      clientOptions.push({
        value: client.id,
        label: client.full_name
      })
    });

    this.operators.forEach((operator: OperatorData) => {
      operatorOptions.push({
        value: operator.id,
        label: operator.username
      })
    })
    this.selectorsData = [
      {
        label: "Choose client",
        options: clientOptions,
        formControl: this.createTableForm.get("client") as FormControl<any>
      },
      {
        label: "Choose operator",
        options: operatorOptions,
        formControl: this.createTableForm.get("operator") as FormControl<any>
      },
      {
        label: "Choose table type",
        options: this.tableTypeOptions,
        formControl: this.createTableForm.get("tableType") as FormControl<any>
      },
      {
        label: "Choose month",
        options: this.selectorMonths,
        formControl: this.createTableForm.get("month") as FormControl<any>
      },
    ]
  }
}
