import {Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {FormControl, Validators} from "@angular/forms";
import {SalaryService} from "../../../../services/salary.service";

export interface SalaryCell {
  data: number | string;
  tableID: number;
  columnName: string;
}

export interface OperatorSalaryCell {
  data: number | string;
  tableID: number;
  columnName: string;
}

export interface SalaryCellData {
  [key: string]: any;
}

@Component({
  selector: 'app-salary-cell',
  templateUrl: './salary-cell.component.html',
  styleUrls: ['./salary-cell.component.css']
})
export class SalaryCellComponent {

  @Input() tableId: number;

  @Input() columnName: string;

  @Input() isInputCell: boolean;

  @Input() cellData: SalaryCell;

  @Input() tableData: any;

  @Output() newData: EventEmitter<any> = new EventEmitter<any>();

  public formControl: FormControl;

  public showInput = false;

  @ViewChild("cellInput") public dataInput: ElementRef;

  @Output()
  public updateTable: EventEmitter<boolean> = new EventEmitter<boolean>(false);

  constructor(
    private service: SalaryService,
  ) {
  }

  public onDoubleClick() {
    this.showInput = true;
    setTimeout(() => {
      this.dataInput;
    }, 0)
  }

  public onEnter() {

    let colName = this.columnName
    if (this.columnName == "operator_fee"){
      colName = 'operator_fee_percentage'
    }
    else if (this.columnName == "gifts_percentage_value"){
       colName = 'gifts_percentage'
    }
    let data = this.formControl.value
    let celldata: { [key: string]: any } = {};
    celldata[colName] = data;
    this.service.change_salary(this.tableId, celldata).subscribe((inputdata) => this.newData.emit(inputdata))
    this.showInput = false;
    this.updateTable.emit(true);

  }


  public ngOnInit() {
    this._initFormControl()
  }

  private _initFormControl() {
    if (this.columnName == "operator_fee") {
      this.formControl = (this.showInput && this.isInputCell) ? new FormControl : new FormControl<any>(this.tableData.operator_fee_percentage, [Validators.required, Validators.pattern('^[0-9]*[.,]?[0-9]+$')]);
    }
    else if (this.columnName == "gifts_percentage_value"){
      this.formControl = (this.showInput && this.isInputCell) ? new FormControl : new FormControl<any>(this.tableData.gifts_percentage, [Validators.required, Validators.pattern('^[0-9]*[.,]?[0-9]+$')]);
    }
    else {
      this.formControl = (this.showInput && this.isInputCell) ? new FormControl : new FormControl<any>(this.cellData, [Validators.required, Validators.pattern('^[0-9]*[.,]?[0-9]+$')]);
    }
  }

  public onClickOutside() {
    this.showInput = false;
  }

}
