import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {RegistrationService} from "../../services/registration.service";
import {Router} from "@angular/router";
import {PopupServiceComponent} from "../popup-service/popup-service.component";
import {MatDialog} from "@angular/material/dialog";
import {AuthService} from "../../services/authentification.service";
import {InputItem} from "../../shared";

export interface IRegistrationForm {
  first_name: FormControl<string>;
  last_name: FormControl<string>;
  email: FormControl<string | null>;
  password: FormControl<string>;
  username: FormControl<string>;
  password_confirm: FormControl<string>;
}

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.css']
})
export class RegistrationComponent implements OnInit {

  public registrationForm: FormGroup<IRegistrationForm>;
  public inputItems: InputItem[];
  public readonly title: string = "Register new user";
  public readonly buttonText: string = "Register";

  constructor(
    public service: RegistrationService,
    private router: Router,
    private dialog: MatDialog,
    private auth: AuthService,
  ) {

    if (this.auth.isAuthenticated()) {
      this.router.navigate(['workpage'])
    }

  }

  public getRegistrationFormControl(controlName: keyof IRegistrationForm): FormControl {
    return this.registrationForm.get(controlName) as FormControl;
  }

  public ngOnInit() {
    this._initRegistrationForm();
  }

  register() {
    this.service.register(this.registrationForm.value as unknown as IRegistrationForm)
      .subscribe((data) => {
        if (data) {
          this.router.navigate(['/workpage'])
        }
      });

  }

  private _initRegistrationForm(): void {
    let emailregex: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    this.registrationForm = new FormGroup<IRegistrationForm>({
      first_name: new FormControl<string>("", {
        validators: [Validators.required, Validators.minLength(3)],
        nonNullable: true
      }),
      last_name: new FormControl<string>("", {
        validators: [Validators.required, Validators.minLength(3)],
        nonNullable: true
      }),
      email: new FormControl<string>("", {validators: [Validators.pattern(emailregex)]}),
      password: new FormControl<string>("", ),
      // {nonNullable: true, validators: [Validators.required, this.checkPassword]}
      password_confirm: new FormControl<string>("", ),
      // {nonNullable: true, validators: [Validators.required, this.checkPassword]}
      username: new FormControl<string>("", {
        validators: [Validators.required, Validators.minLength(3)],
        nonNullable: true
      }),
    },)


    this.inputItems = [
      {
        control: this.getRegistrationFormControl("first_name"),
        placeholder: "Stepan",
        label: "First Name"
      },
      {
        control: this.getRegistrationFormControl("last_name"),
        placeholder: "Bandera",
        label: "Last Name"
      },
      {
        control: this.getRegistrationFormControl("username"),
        placeholder: "OFStepan",
        label: "Login"
      },
      {
        control: this.getRegistrationFormControl("email"),
        placeholder: "SlavaUkraini@gmail.com",
        label: "Email"
      },
      {
        control: this.getRegistrationFormControl("password"),
        placeholder: "StyopaUbiyca1488",
        label: "Password"
      },
      {
        control: this.getRegistrationFormControl("password_confirm"),
        placeholder: "StyopaUbiyca1488",
        label: "Repeat Password"
      }

    ]
  }

  checkPassword(control: FormControl) {
    let enteredPassword = control.value
    let passwordCheck = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,})/;
    return (!passwordCheck.test(enteredPassword) && enteredPassword) ? {'requirements': true} : null;
  }

  showPopup(message: string, popuptype: boolean) {
    const dialogRef = this.dialog.open(PopupServiceComponent, {
      width: '250px',
      data: {message: message, popupType: popuptype},

    });
  }


  registerUser(form: FormGroup): void {

    this.service.register(form.value).subscribe(
      data => {
        this.showPopup(data, true,)
        this.router.navigate(['/login'])
      },
      error => {
        console.error('Произошла ошибка:', error,);
        this.showPopup(error, false,)
      }
    );
    this.registrationForm.reset();
  }

  protected readonly RegistrationService = RegistrationService;

  public navigateToLogin(): void {
    this.router.navigate(["/login"]);
  }
}
