import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-total-by-project-and-month',
  templateUrl: './total-by-project-and-month.component.html',
  styleUrls: ['./total-by-project-and-month.component.css']
})
export class TotalByProjectAndMonthComponent {


  @Input()
  public Sum: any;


}
