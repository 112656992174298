import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {AuthService} from "./authentification.service";
import {environment} from "../../env/environment";
import {SalaryCellData} from "../components/salary/components/salary-cell/salary-cell.component";

@Injectable()

export class SalaryService {

  constructor(private http: HttpClient){
  }

  public get_salary(month: number, year:number, project: string){
    return this.http.get(`${environment.apiUrl}/salary/salary/?month=${month}&year=${year}&project=${project}`)
  }

  public change_salary(salary_id: number, data: any){
    const modifiedData = JSON.parse(JSON.stringify(data).replace(/\"([\d.]+),([\d.]+)\"/g, '$1.$2'));
    return this.http.patch<SalaryCellData>(`${environment.apiUrl}/salary/salary/${salary_id}/`, modifiedData)
  }

  public get_operator_salary(month:number, year:number, project:string){
    return this.http.get(`${environment.apiUrl}/salary/operator_salary/?month=${month}&year=${year}&project=${project}`)
  }

  public change_operator_salary(salary_id: number, data: any){
    const modifiedData = JSON.parse(JSON.stringify(data).replace(/\"([\d.]+),([\d.]+)\"/g, '$1.$2'));
    return this.http.patch<SalaryCellData>(`${environment.apiUrl}/salary/operator_salary/${salary_id}/`, modifiedData)
  }

  public total_salary_by_month(month:number, year: number, project: string){
    return this.http.get(`${environment.apiUrl}/salary/total_salary_by_month/?month=${month}&year=${year}&project=${project}`)
  }

}
