import {Injectable} from "@angular/core";
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {catchError, Observable, of, Subscription, tap, throwError} from "rxjs";
import {IRegistrationForm} from "../components/registration/registration.component";
import {FormGroup} from "@angular/forms";
import { environment } from 'src/env/environment';

@Injectable()


export class RegistrationService{
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(`Server returned code ${error.status}, body was: ${error.error}`);
    }
    return throwError(`Что-то пошло не так; попробуйте позже. ${error.message} ${error.status} ${error.statusText}` );
  }

  constructor(private http: HttpClient){
  }

  public register(data: IRegistrationForm): Observable<any>{
    return this.http.post(`${environment.apiUrl}/users/registration/register/`, data)
      .pipe(
        catchError(this.handleError)
      )
  }

}
