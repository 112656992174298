import {FormControl} from "@angular/forms";
import {TemplateRef} from "@angular/core";
import {TableType} from "../../components/create-table/create-table.component";

export interface OnlyFansTable extends TableDataCells{
  id: number;
  clientSurname: string;
  clientName: string;
  tableType: boolean;
  tabledata_set: TableData[];
  operator: number;
  operatorName: string;
}

export interface GoldenBrideTable extends TableDataCells{
  id: number;
  clientSurname: string;
  clientName: string;
  tableType: boolean;
  tableId: string;
  tabledata_set: TableData[];
  operator: number;
  operatorName: string;
}

export interface TableDataCells {
  [key: string]: CellWithData | string | boolean | TableData[] | number;
}

export interface CellWithData {
  data: number;
  id: number;
}

export interface TableData{
  id: number
  day: string
  data: number
  is_day_off: boolean
}

export interface LoginData{
  login : string
  password : string
}


export interface CreateOnlyfansTable{
  month : FormControl<number>,
  client: FormControl<number>,
  operator: FormControl<number>,
  tableType: FormControl<TableType>,
}

export interface CreateGoldenBrideTable{
  month : FormControl<number>,
  client: FormControl<number>,
  operator: FormControl<number>,
  accountId: FormControl<string>,
  project: FormControl<string>
}

export interface OperatorData{
  id: number,
  username: string,
  first_name: string,
  last_name: string

}


export interface OperatorAndClientList {
  operator: OperatorData[]
  client: ClientProfile[]
}

export interface shortClientList{
  full_name: string;
  id: number;
}

export interface ClientProfile{
    id: number,
    full_name: string,
    name: string,
    surname: string,
    country: string | null,
    photo: string | null,
    date_of_birth: string| null,
    managers: number[],
    status: string
}

export interface Client {
  id: number;
  surname: string;
  name: string;
  // Добавьте другие свойства клиента здесь
}


export interface ClientManager{
  id: number,
  username: string,
  first_name: string,
  last_name: string,
}

export interface CreateClient {
  name: FormControl<string>,
  surname: FormControl<string>,
  country: FormControl<string>,
  photo: FormControl<string>,
  dateOfBirth: FormControl<string>,
  managers: FormControl<number>
}


export interface ChangeClientBaseInfo {
  id: FormControl<number>
  name: FormControl<string>,
  surname: FormControl<string>,
  country: FormControl<string>,
  photo: FormControl<string>,
  dateOfBirth: FormControl<string>,
  managers: FormControl<ClientManager[]>
}

export const enum Month {
    JANUARY = "January",
    FEBRUARY = "February",
    MARCH = "March",
    APRIL = "April",
    MAY = "May",
    JUNE = "June",
    JULY = "July",
    AUGUST = "August",
    SEPTEMBER = "September",
    OCTOBER = "October",
    NOVEMBER = "November",
    DECEMBER = "December"
}

export const months: Month[] = [
  Month.JANUARY,
  Month.FEBRUARY,
  Month.MARCH,
  Month.APRIL,
  Month.MAY,
  Month.JUNE,
  Month.JULY,
  Month.AUGUST,
  Month.SEPTEMBER,
  Month.OCTOBER,
  Month.NOVEMBER,
  Month.DECEMBER
];
export const monthDaysMap: Record<Month, number> = {
  [Month.JANUARY]: 31,
  [Month.FEBRUARY]: 29,
  [Month.MARCH]: 31,
  [Month.APRIL]: 30,
  [Month.MAY]: 31,
  [Month.JUNE]: 30,
  [Month.JULY]: 31,
  [Month.AUGUST]: 31,
  [Month.SEPTEMBER]: 30,
  [Month.OCTOBER]: 31,
  [Month.NOVEMBER]: 30,
  [Month.DECEMBER]: 31
}

export interface ITabInfo {
  label: Month;
  content: TemplateRef<any>;
}


export const selectorMonths = [
      {
        value: 1,
        label: "January"
      },
      {
        value: 2,
        label: "February"
      },
      {
        value: 3,
        label: "March"
      },
      {
        value: 4,
        label: "April"
      },
      {
        value: 5,
        label: "May"
      },
      {
        value: 6,
        label: "June"
      },
      {
        value: 7,
        label: "July"
      },
      {
        value: 8,
        label: "August"
      },
      {
        value: 9,
        label: "September"
      },
      {
        value: 10,
        label: "October"
      },
      {
        value: 11,
        label: "November"
      },
      {
        value: 12,
        label: "December"
      }
    ]

export interface JumpForLoveTable extends TableDataCells{
  id: number;
  clientSurname: string;
  clientName: string;
  tableType: boolean;
  tableId: string;
  tabledata_set: TableData[];
  operator: number;
  operatorName: string;
}

export interface CreateDateMeTable {
  month : FormControl<number>,
  client: FormControl<number>,
  operator: FormControl<number>,
  accountId: FormControl<string>,
  project: FormControl<string>
}


export interface DateMeTable extends TableDataCells{
  id: number;
  clientSurname: string;
  clientName: string;
  tableType: boolean;
  tableId: string;
  tabledata_set: TableData[];
  operator: number;
  operatorName: string;
}

export interface CreateDateMeTable{
  month : FormControl<number>,
  client: FormControl<number>,
  operator: FormControl<number>,
  accountId: FormControl<string>,
  project: FormControl<string>
}

export interface CharmDateTable extends TableDataCells{
  id: number;
  clientSurname: string;
  clientName: string;
  tableType: boolean;
  tableId: string;
  tabledata_set: TableData[];
  operator: number;
  operatorName: string;
}

export interface CreateCharmDateTable{
  month : FormControl<number>,
  client: FormControl<number>,
  operator: FormControl<number>,
  accountId: FormControl<string>,
  project: FormControl<string>
}

export interface JumpForLoveTable extends TableDataCells{
  id: number;
  clientSurname: string;
  clientName: string;
  tableType: boolean;
  tableId: string;
  tabledata_set: TableData[];
  operator: number;
  operatorName: string;
}

export interface CreateJumpForLoveTable{
  month : FormControl<number>,
  client: FormControl<number>,
  operator: FormControl<number>,
  accountId: FormControl<string>,
  project: FormControl<string>
}


export interface RomanceCompassTable extends TableDataCells{
  id: number;
  clientSurname: string;
  clientName: string;
  tableType: boolean;
  tableId: string;
  tabledata_set: TableData[];
  operator: number;
  operatorName: string;
}

export interface CreateRomanceCompassTable{
  month : FormControl<number>,
  client: FormControl<number>,
  operator: FormControl<number>,
  accountId: FormControl<string>,
  project: FormControl<string>
}

export interface AnastasiaDatingTable extends TableDataCells{
  id: number;
  clientSurname: string;
  clientName: string;
  tableType: boolean;
  tableId: string;
  tabledata_set: TableData[];
  operator: number;
  operatorName: string;
}

export interface CreateAnastasiaDatingTable{
  month : FormControl<number>,
  client: FormControl<number>,
  operator: FormControl<number>,
  tableType: FormControl<string>,
  accountId: FormControl<string>,
  project: FormControl<string>,
}

export interface InputItem {
  control: FormControl,
  label: string,
  placeholder: string,
  isDateInput?: boolean
}

export interface CreateOnlyFansTable {
  month : FormControl<number>,
  client: FormControl<number>,
  operator: FormControl<number>,
  tableType: FormControl<string>,
  project: FormControl<string>,
}

export interface OnlyFansTable extends TableDataCells{
  id: number;
  clientSurname: string;
  clientName: string;
  tableType: boolean;
  tabledata_set: TableData[];
  operator: number;
  operatorName: string;
}

export interface CreateOnlyFansTable {
  month : FormControl<number>,
  client: FormControl<number>,
  operator: FormControl<number>,
  tableType: FormControl<string>,
  project: FormControl<string>,
}

export interface FanslyTable extends TableDataCells{
  id: number;
  clientSurname: string;
  clientName: string;
  tableType: boolean;
  tabledata_set: TableData[];
  operator: number;
  operatorName: string;
}

export interface CreateFanslyTable {
  month : FormControl<number>,
  client: FormControl<number>,
  operator: FormControl<number>,
  project: FormControl<string>,
}

export interface FancentroTable extends TableDataCells{
  id: number;
  clientSurname: string;
  clientName: string;
  tableType: boolean;
  tabledata_set: TableData[];
  operator: number;
  operatorName: string;
}

export interface CreateFancentroTable {
  month : FormControl<number>,
  client: FormControl<number>,
  operator: FormControl<number>,
  project: FormControl<string>,
}

export interface TotalSumByMonth {
  total_usdt: number
}
