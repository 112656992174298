<div id="Login">
<h1 mat-dialog-title>Login page</h1>
<div mat-dialog-content (keyup.enter) ="onNoClick()">
  <mat-form-field>
    <input matInput [(ngModel)]="data.login" placeholder="Login">
  </mat-form-field>
  <mat-form-field>
    <input matInput [(ngModel)]="data.password" type="password" placeholder="Password">
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button  (click)="onNoClick()">Login</button>
</div>
</div>
