import {Component, Input} from '@angular/core';
import {FormControl} from "@angular/forms";

export interface ISelectorOption<Type = number> {
  value: Type;
  label: string;
}

@Component({
  selector: 'app-selector',
  templateUrl: './selector.component.html',
  styleUrls: ['./selector.component.css']
})
export class SelectorComponent {
  isOpen = false;
  @Input()
  selectedItem: string | null = null;

  @Input()
  public options: ISelectorOption<any>[];

  @Input()
  public formControl: FormControl;

  @Input()
  public label: string;

  toggleOptions() {
    this.isOpen = !this.isOpen;
  }

  selectOption(option: ISelectorOption) {
    this.selectedItem = option.label;
    this.isOpen = false;
    this.formControl.setValue(option.value)
  }
}
