<div class="table-header-scroll-container">

  <div class="table-wrapper">
  <app-total-by-project-and-month
  [Sum]="totalByMonth"

  ></app-total-by-project-and-month>
</div>

  <div class="table-header-with-title-wrapper">
<table mat-table [dataSource]="dataSource">
  <ng-container *ngFor="let column of displayedColumns">
    <ng-container [matColumnDef]="column">
      <th mat-header-cell *matHeaderCellDef [innerHTML]="column"></th>
      <td mat-cell *matCellDef="let element">{{ (element[column] != null && column !== 'Site') ? (+element[column]).toFixed(2) : element[column] }}</td>
    </ng-container>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
  </div>

</div>
