import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class YearService {
  private _currentYear: BehaviorSubject<number>;

  constructor() {
    const storedYear = localStorage.getItem('selectedYear');
    this._currentYear = new BehaviorSubject<number>(storedYear ? +storedYear : 2023);
  }

  get currentYear$() {
    return this._currentYear.asObservable();
  }

  get currentYear() {
    return this._currentYear.getValue();
  }

  set currentYear(year: number) {
    localStorage.setItem('selectedYear', year.toString());
    this._currentYear.next(year);
    location.reload();
  }
}
