import {Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {FormControl, Validators} from "@angular/forms";
import {SalaryService} from "../../../../../services/salary.service";
import {OperatorSalaryComponent} from "../../operator-salary.component";
import {OperatorSalaryCell} from "../../../components/salary-cell/salary-cell.component";

@Component({
  selector: 'app-operator-salary-cell',
  templateUrl: './operator-salary-cell.component.html',
  styleUrls: ['./operator-salary-cell.component.css']
})
export class OperatorSalaryCellComponent {

  @Input() tableId: number;

  columnName: string = 'USD'

  @Input() isInputCell: boolean;

  @Input() cellData: OperatorSalaryCell;

  @Input() tableData: any;

  @Output() newData: EventEmitter<any> = new EventEmitter<any>();

  public formControl: FormControl;

  public showInput = false;

  @ViewChild("cellInput") public dataInput: ElementRef;

  @Output()
  public updateTable: EventEmitter<boolean> = new EventEmitter<boolean>(false);

  constructor(
    private service: SalaryService,
  ) {
  }

  public onDoubleClick() {
    this.showInput = true;
    setTimeout(() => {
      this.dataInput;
    }, 0)
  }

  public onEnter() {
    let colName: string; // Объявляем переменную здесь, чтобы она была доступна во всей функции

    if (this.tableData['name'] == 'Bonus') {
      colName = 'bonus_usdt';
    } else if (this.tableData['name'] == 'Advance') {
      colName = 'advance_usdt';
    } else if (this.tableData['name'] == 'Penalty') {
      colName = 'penalty_usdt';
    } else {
      colName = 'none';
    }

    let data = this.formControl.value;
    let celldata: { [key: string]: any } = {};
    celldata[colName] = data;
    this.service.change_operator_salary(this.tableId, celldata).subscribe((inputdata) => this.newData.emit(inputdata));
    this.showInput = false;
    this.updateTable.emit(true);
  }


  public ngOnInit() {
    this._initFormControl()
  }

  private _initFormControl() {
    this.formControl = (this.showInput && this.isInputCell) ? new FormControl : new FormControl<any>(this.cellData, [Validators.required, Validators.pattern('^[0-9]*[.,]?[0-9]+$')]);
  }

  public onClickOutside() {
    this.showInput = false;
  }

}
