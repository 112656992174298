<div *ngIf="data.popupType; else elseBlock" >
  <div class="success" style="background: green;">
  <h1 mat-dialog-title>Вы успешно зарегистрировались!</h1>
  <div mat-dialog-content>
  Залогиньтесь в системе!
  </div>
  <div mat-dialog-actions>
  <button mat-button mat-dialog-close>Ок</button>
  </div>
    </div>
</div>
<ng-template  #elseBlock>
  <div class="error" style="background: red;">
<h1 mat-dialog-title>Что-то пошло не так</h1>
  <div mat-dialog-content>
  <p></p>
    <p>Error: {{data.message | json}}</p>
  </div>
  <div mat-dialog-actions>
  <button mat-button mat-dialog-close>Ок</button>
  </div>
    </div>
</ng-template>
