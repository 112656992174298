<app-box [title]="title" [buttonText]="buttonText" (buttonClick)="onNoClick()">
  <form class="my-form" [formGroup]="createTableForm">
    <div (keyup.enter)="onNoClick()">
      <ng-container *ngFor="let selectorDataItem of selectorsData">
        <app-selector [label]="selectorDataItem.label" [formControl]="selectorDataItem.formControl"
                      [options]="selectorDataItem.options"></app-selector>
      </ng-container>
      <app-input [label]="input.label" [placeholder]="input.placeholder" [control]="createTableForm.controls.accountId"
                 [isDateInput]="!!input.isDateInput"></app-input>
    </div>
  </form>
</app-box>
