<div class="mat-card-wrapper">
  <app-box [title]="title" [buttonText]="buttonText" (buttonClick)="onClick()">
    <form (ngSubmit)="onClick()">
      <ng-container *ngFor="let item of inputItems">
        <app-input [label]="item.label" [placeholder]="item.placeholder" [control]="item.control"></app-input>
      </ng-container>
    </form>
    <p>Don't have an account? <a  class="link" (click)="navigateToRegistration()">Sign up</a></p>
  </app-box>
</div>
