import {ActivatedRouteSnapshot, Resolve} from "@angular/router";
import {Injectable} from "@angular/core";
import {ClientService} from "../../services/client.service";

@Injectable({
  providedIn: 'root',
})

export class ClientProfileResolver implements Resolve<any> {
  constructor(
    private service: ClientService
  ) {
  }

  resolve(route: ActivatedRouteSnapshot) {
    const id = route.params["id"];

    return this.service.getClientProfile(id)
  }
}
