import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from 'src/env/environment';

@Injectable()
export class TableHeaderService {

  constructor(private http: HttpClient) {
  }
  public getHeaderData(month: number, year: number, project: string): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/core/table_header/?month=${month}&year=${year}&project=${project}`, {})
  }
}


