<app-header></app-header>


<button class="btn btn-success" (click)="navigateToCreateClient()">Create new Client</button>

<div class='list-group' *ngFor="let manager of Object.keys(clientListsByManagerAndCategory)">
  <h3>{{ manager }}</h3>
  <div *ngIf="Array.isArray(clientListsByManagerAndCategory[manager])">
    <ng-container *ngFor="let client of clientListsByManagerAndCategory[manager]">
      <a href="/clientprofile/{{ client.id }}" class="list-group-item list-group-item-action" aria-current="true">
        {{ client.surname }}, {{ client.name }}
      </a>
    </ng-container>
  </div>
  <div class='list-group' *ngIf="!Array.isArray(clientListsByManagerAndCategory[manager])">
    <div *ngFor="let category of Object.keys(clientListsByManagerAndCategory[manager])">
      <h4>{{ category }}</h4>
      <ng-container *ngFor="let client of clientListsByManagerAndCategory[manager][category]">
        <a href="/clientprofile/{{ client.id }}" class="list-group-item list-group-item-action" aria-current="true">
          {{ client.surname }}, {{ client.name }}
        </a>
      </ng-container>
    </div>
  </div>
</div>




