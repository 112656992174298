import {Component, OnInit} from '@angular/core';
import {ClientService} from "../../services/client.service";
import {ChangeClientBaseInfo, ClientManager, ClientProfile} from "../../shared/interfaces/interfaces";
import {ActivatedRoute} from "@angular/router";
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'app-client-profile',
  templateUrl: './client-profile.component.html',
  styleUrls: ['./client-profile.component.css']
})
export class ClientProfileComponent implements OnInit {

  public clientData: ClientProfile;
  public myForm: FormGroup;
  public clientManagers: ClientManager[];
  public changeBasicInfo: FormGroup;
  public editModeControls: any = {
    AnastasiaDating: false,
    DateMe: false,
    CharmDate: false,
    GoldenBride: false,
    Fansly: false,
    OnlyFans: false,
    BaseInfo: false,
    Soricals: false
  }
  value: any;
  public statusList: string[] = ['Ukraine', 'International', 'Special_Status', 'Dont_cooperate']
  public initialStatus: string
  public initialManager: any

  constructor(
    private service: ClientService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder
  ) {
  }


  ngOnInit(): void {
    this.clientData = this.route.snapshot.data["clientData"]
    this.getClientManagerList()
    this.initialStatus= this.clientData.status || '';
    console.log(this.clientManagers)
    this.myForm = this.formBuilder.group({
      AnastasiaDating: this.formBuilder.array([]),
      DateMe: this.formBuilder.array([]),
      CharmDate: this.formBuilder.array([]),
      GoldenBride: this.formBuilder.array([]),
      Fansly: this.formBuilder.array([]),
      OnlyFans: this.formBuilder.array([]),
    });

  }

  public fieldList(project: string) {
    return this.myForm.get(project) as FormArray;
  }

  addField(project: string) {
    this.fieldList(project).push(this.createFieldGroup());
  }

  removeField(index: number, project: string) {
    this.fieldList(project).removeAt(index);
  }

  private createFieldGroup() {
    return this.formBuilder.group({
      name: ['', Validators.required,],
      value: ['', Validators.required],
    });
  }

  toggleEditMode(project: any) {
    this.editModeControls[project] = !this.editModeControls[project];
  }

  private getClientManagerList() {
    this.service.getClientManagers().subscribe(
      data => this.clientManagers = data
    )
  }

  public getClientManager(): ClientManager {
    const managerList = this.clientManagers
    const targetId = this.clientData?.managers
    return (managerList || []).find((manager: ClientManager) => manager.id == targetId[0]) as ClientManager;

  }

  public _ChangeBaseInfoForm() {
    this.changeBasicInfo = new FormGroup<ChangeClientBaseInfo>(
      <ChangeClientBaseInfo>{
        id: new FormControl(this.clientData.id),
        name: new FormControl(this.clientData.name, {nonNullable: true}),
        surname: new FormControl(this.clientData.surname, {nonNullable: true}),
        country: new FormControl(this.clientData.country, {nonNullable: true}),
        photo: new FormControl(this.clientData.photo, {nonNullable: true}),
        dateOfBirth: new FormControl<string | null>(this.clientData.date_of_birth, {nonNullable: true}),
        managers: new FormControl<ClientManager[]>(this.clientManagers, {nonNullable: true}),
        status: new FormControl<ClientManager[]>(this.clientManagers, {nonNullable: true}),
      }
    )

  }

  public changeBaseInfo() {
    this.service.changeBaseInfo(this.changeBasicInfo.value).subscribe(
      data => console.log(data)
    )


  }


}



