import {Component, Input, OnInit, SimpleChanges} from '@angular/core';
import {Month} from "../../../shared/interfaces/interfaces";
import {MatTableDataSource} from "@angular/material/table";
import {Observable} from "rxjs";

export const operatorSalaryNameMap: Record<string, string> = {
  'total_usdt': 'Salary',
  'bonus_usdt': 'Bonus',
  'penalty_usdt': 'Penalty',
  'advance_usdt': 'Advance',
  'clear_total_usdt': 'Total',
}

export interface TableData {
  id: number;
  full_name: string;
  clear_total_usdt: number;
  bonus_usdt: number;
  penalty_usdt: number;
  advance_usdt: number;
  total_usdt: number;
}

@Component({
  selector: 'app-operator-salary',
  templateUrl: './operator-salary.component.html',
  styleUrls: ['./operator-salary.component.css']
})
export class OperatorSalaryComponent implements OnInit {

  public inputColumns: string[]

  @Input()
  data: any

  @Input()
  currentMonth: Month

  tabledata: TableData[] = [];

  dataSource: any

  displayedColumns: string[] = ['name', 'USD']

  ngOnInit() {
    this.initDataSource()
    this._initColumns()
  }

  ngOnChanges(changes: SimpleChanges) {
  if (changes['data'] && this.data && this.data.length > 0) {
    this.dataSource = new MatTableDataSource<any>([
      { name: 'Salary', USD: this.data[0]?.total_usdt || 0 },
      { name: 'Bonus', USD: this.data[0]?.bonus_usdt || 0 },
      { name: 'Penalty', USD: this.data[0]?.penalty_usdt || 0 },
      { name: 'Advance', USD: this.data[0]?.advance_usdt || 0 },
      { name: 'Total', USD: this.data[0]?.clear_total_usdt || 0 },
    ]);
  }
  else {
    this.dataSource = new MatTableDataSource<any>([]);
  }
}


  public getColumnHeader(columnName: string): string {
    return operatorSalaryNameMap[columnName] ? operatorSalaryNameMap[columnName] : columnName;
  }

  private _initColumns() {
    this.inputColumns = ["Bonus", "Penalty", "Advance"]
  }

  public inputColumn(name: string) {
    return this.inputColumns.includes(name)
  }

  public getColumnTotal(column: string): number | string {
  if (!this.data || !this.data.length) {
    return ''; // Возвращаем пустую строку, если данные отсутствуют или массив пустой
  }

  if (column === 'name') {
    return '';
  } else {
    const clearTotalUsdt = this.data[0]['clear_total_usdt'];
    return clearTotalUsdt ||  ''; // Возвращаем отформатированное значение, если оно существует, иначе возвращаем пустую строку
  }
}

  public handleNewData(event: any) {
  const newDataId = event.id;
  const dataIndex = this.data.findIndex((item: any) => item.id === newDataId);
  if (dataIndex !== -1) {
    this.data[dataIndex] = event;
    this.refreshDataSource();
  }
}

  private refreshDataSource() {
  if (this.data && this.data.length > 0) {
    this.dataSource.data = [
      { name: 'Salary', USD: this.data[0]?.total_usdt || 0 },
      { name: 'Bonus', USD: this.data[0]?.bonus_usdt || 0 },
      { name: 'Penalty', USD: this.data[0]?.penalty_usdt || 0 },
      { name: 'Advance', USD: this.data[0]?.advance_usdt || 0 },
      { name: 'Total', USD: this.data[0]?.clear_total_usdt || 0 },
    ];
  }
  else {
    this.dataSource = new MatTableDataSource<any>([]);
  }

}

public initDataSource() {
  if (this.data && this.data.length > 0) {
    this.dataSource = new MatTableDataSource<any>([
      { name: 'Salary', USD: this.data[0]?.total_usdt || 0 },
      { name: 'Bonus', USD: this.data[0]?.bonus_usdt || 0 },
      { name: 'Penalty', USD: this.data[0]?.penalty_usdt || 0 },
      { name: 'Advance', USD: this.data[0]?.advance_usdt || 0 },
      { name: 'Total', USD: this.data[0]?.clear_total_usdt || 0 },
    ]);
  } else {
    this.dataSource = new MatTableDataSource<any>([]);
  }
}
}
