import { Injectable } from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router} from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PermissionGuard implements CanActivate {

  constructor(private route: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    const requiredPermission = route.data['requiredPermission'];
    let perm = localStorage.getItem("perms");
    if (perm !== null) {
      let perms = Object.values(JSON.parse(perm));
      const hasAtLeastOnePermission = requiredPermission.some((permission: unknown) => perms.includes(permission));

      if (hasAtLeastOnePermission) {
        return true;
      } else {
        return this.route.navigate(['/login']);
      }
    } else {
      return false;
    }
  }
}
