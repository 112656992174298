import {Component, OnInit} from '@angular/core';
import {ClientManager, CreateClient, InputItem} from "../../shared/interfaces/interfaces";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ClientService} from "../../services/client.service";
import {PopupServiceComponent} from "../popup-service/popup-service.component";
import {MatDialog} from "@angular/material/dialog";
import {ISelectorOption} from "../../shared/components/selector/selector.component";

@Component({
  selector: 'app-create-client',
  templateUrl: './create-client.component.html',
  styleUrls: ['./create-client.component.css'],
})
export class CreateClientComponent implements OnInit{

  public createClientForm: FormGroup<CreateClient>;
  public managers: ISelectorOption<number>[];
  public inputItems: InputItem[];
  public readonly title: string = "Create new Client";
  public readonly buttonText: string = "Create client";

  constructor(private service: ClientService,
              private dialog: MatDialog,
  ) {
  }

  public getCreateClientFormControl(controlName: keyof CreateClient) {
    return this.createClientForm.get(controlName) as FormControl;
  }


  public ngOnInit() {
    this._initCreateClientForm()
    this._getPromoManagersList()

  }

  private _initCreateClientForm() {
    this.createClientForm = new FormGroup<CreateClient>(
      <CreateClient>{
        name: new FormControl<string>("", Validators.required),
        surname: new FormControl<string>("", Validators.required),
        country: new FormControl<string>("", Validators.required),
        photo: new FormControl<string>("", Validators.required),
        dateOfBirth: new FormControl<string>("", Validators.required),
        managers: new FormControl<number>(0, Validators.required)
      }
    )

    this.inputItems = [
      {
        control: this.getCreateClientFormControl("name"),
        placeholder: "John",
        label: "Name"
      },
      {
        control: this.getCreateClientFormControl("surname"),
        placeholder: "Smith",
        label: "Surname"
      },
      {
        control: this.getCreateClientFormControl("country"),
        placeholder: "Ukraine",
        label: "Country"
      },
      {
        control: this.getCreateClientFormControl("photo"),
        placeholder: "https://yourphoto.com",
        label: "Photo Link"
      },
      {
        control: this.getCreateClientFormControl("dateOfBirth"),
        placeholder: "01-01-2000",
        label: "Date of Birth",
        isDateInput: true
      }
    ]
  }

  private showPopup(message: string, popuptype: boolean) {
    const dialogRef = this.dialog.open(PopupServiceComponent, {
      width: '250px',
      data: {message: message, popupType: popuptype},

    });
  }

  private _getPromoManagersList() {
    this.service.getClientManagers().subscribe(
      data => {
        this.managers = []
        data.forEach((manager: ClientManager) => {
          this.managers.push({
            value: manager.id,
            label: `${manager.first_name} ${manager.last_name}`
          })
        })
      },
      error => (
        this.showPopup(error, false)
      )
    )
  }

  createClient(form: FormGroup) {
    this.service.createClient(form.value).subscribe(
      data => this.showPopup(data, true)
    )
  }


}
