<app-header></app-header>

<div class="selector">
<select (change)="onMonthChange($event)" [(ngModel)]="selectedMonth">
  <option *ngFor="let month of months" [value]="month.value">{{ month.name }}</option>
</select>
  </div>
<div class="total-sum-container">
<table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
  <!-- Site Column -->
  <ng-container matColumnDef="Site">
    <th mat-header-cell *matHeaderCellDef> Site</th>
    <td mat-cell *matCellDef="let element"> {{ element.Site }}</td>
  </ng-container>

  <!-- Total Column -->
  <ng-container matColumnDef="Total">
    <th mat-header-cell *matHeaderCellDef> Total</th>
    <td mat-cell *matCellDef="let element"> {{ element.Total }}</td>
  </ng-container>

  <!-- Header Row -->
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>

  <!-- Data Rows -->
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

</table>
</div>
