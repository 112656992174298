import {ChangeDetectorRef, Component, Input, SimpleChanges} from '@angular/core';
import {Month} from "../../shared/interfaces/interfaces";
import {Observable} from "rxjs";
import {SalaryService} from "../../services/salary.service";


let projectName;
export const salaryColumnNameMap: Record<string, string> = {
  "client_full_name": "Client Name",
  "table_type": "Table Type",
  "account_id": "Account Id",
  "clear_total": "Clear Total",
  "total_sum": "Total",
  "gifts": "Gift минус",
  "operator_fee": projectName === 'fansly' || projectName === 'fancentro' || projectName === 'OnlyFans' ? "20% = $" : "40% = $",
  "gifts_percentage_value": "Gifts 5%",
  "live_chat_bonus": "LiveChat Bonus",
  "write_offs": "Write-off $",
  "salary_dollars": "ЗП $",
  "refunds": 'Refunds'
}


@Component({
  selector: 'app-salary',
  templateUrl: './salary.component.html',
  styleUrls: ['./salary.component.css']
})
export class SalaryComponent {

  @Input()
  public projectName: string

  @Input()
  data: any

  @Input()
  displayedColumns: string[]

  @Input()
  operatorSalaryData: any

  public dataSource: Observable<any>;

  @Input()
  currentMonth: Month

  public inputColumns: string[]

  public status: string = ''

  public text: string = '';
  public editing: boolean = false;


  constructor(
    public service: SalaryService,
  ) {
  }

  ngOnChanges(changes: SimpleChanges) {
  if (changes['data']) {
    this.status = this.operatorSalaryData[0].pay_status;
    this.text = this.operatorSalaryData[0].comments_field;
    this.refreshDataSource();
  }
  if (changes['projectName']) {
      this.updateOperatorFee();
    }
}

  ngOnInit() {
    this._initColumns()
  }


  private refreshDataSource() {
  this.dataSource = new Observable<any>((observer) => {
    observer.next(this.data);
    observer.complete();
  });
}
  private _initColumns() {
    this.inputColumns = ["gifts", "operator_fee", "gifts_percentage_value", "live_chat_bonus","write_offs", "refunds" ]
  }

  public getColumnHeader(columnName: string): string {
    return salaryColumnNameMap[columnName] ? salaryColumnNameMap[columnName] : columnName;
  }

  public getColumnTotal(column: string): number | string {
    let total = 0;
    if (column == 'account_id') {
      return ''
    } else {
      for (const element of this.data) {
        total += +element[column] || 0;
      }
      return total !== 0 ? total.toFixed(2) : '';
    }
  }

  public inputColumn(column: string) {
    return this.inputColumns.includes(column)
  }

  public updateTable(){
  }

 handleNewData(event: any) {
  const newDataId = event.id;
  const dataIndex = this.data.findIndex((item: any) => item.id === newDataId);

  if (dataIndex !== -1) {
    this.data[dataIndex] = event;
    this.data = [...this.data];
  }
}

private updateOperatorFee() {
    if (this.projectName === 'Fansly' || this.projectName === 'Fancentro' || this.projectName === 'OnlyFans') {
      salaryColumnNameMap["operator_fee"] = "20% = $";
    } else {
      salaryColumnNameMap["operator_fee"] = "40% = $";
    }
  }

  public onStatusChange(event: Event) {
    let celldata: { [key: string]: any } = {};
    celldata['pay_status'] = this.status;
    this.service.change_operator_salary(this.operatorSalaryData[0].id,celldata).subscribe(
      data => console.log(data)
    )
  }


  startEditing() {
    this.editing = true;
  }

  stopEditing(newValue: string) {
    this.text = newValue;
    this.editing = false;
    let celldata: { [key: string]: any } = {};
    celldata['comments_field'] = this.text;
    this.service.change_operator_salary(this.operatorSalaryData[0].id,celldata).subscribe(
      data => console.log(data)
    )

  }

  formatDate(dateString: string): string {
    const [day, month, year] = dateString.split('-').map(Number);
    const date = new Date(year, month - 1, day); // Месяц начинается с 0 в объекте Date
    return date.toLocaleString('en-EN', { month: 'long', year: 'numeric' });
  }
}
