<app-header></app-header>
<div class="mat-card-wrapper">
  <app-box [title]="title" [buttonText]="buttonText" (buttonClick)="createClient(createClientForm)">
    <form class="my-form" [formGroup]="createClientForm">
      <ng-container *ngFor="let item of inputItems">
        <app-input [label]="item.label" [placeholder]="item.placeholder" [control]="item.control" [isDateInput]="!!item.isDateInput"></app-input>
      </ng-container>

      <app-selector [options]="managers" [formControl]="getCreateClientFormControl('managers')" label="Choose manager"></app-selector>
    </form>
  </app-box>
</div>
