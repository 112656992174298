<!--<table mat-table [dataSource]="dataSource" class="mat-elevation-z8">-->
<!--   Колонка "name" -->
<!--  <ng-container matColumnDef="name">-->
<!--    <th mat-header-cell *matHeaderCellDef> Name </th>-->
<!--    <td mat-cell *matCellDef="let element"> {{ element.name }} </td>-->
<!--  </ng-container>-->
<!---->
<!--   Колонка "usdt" -->
<!--  <ng-container matColumnDef="usdt">-->
<!--    <th mat-header-cell *matHeaderCellDef> usdt </th>-->
<!--    <td mat-cell *matCellDef="let element"> {{ element.usdt }} </td>-->
<!--  </ng-container>-->
<!---->
<!--   Колонка "uah" -->
<!--  <ng-container matColumnDef="uah">-->
<!--    <th mat-header-cell *matHeaderCellDef> uah </th>-->
<!--    <td mat-cell *matCellDef="let element"> {{ element.uah }} </td>-->
<!--  </ng-container>-->
<!---->
<!--   Header and Row Definitions -->
<!--  <tr mat-header-row *matHeaderRowDef="['name', 'usdt', 'uah']"></tr>-->
<!--  <tr mat-row *matRowDef="let row; columns: ['name', 'usdt', 'uah']"></tr>-->
<!--</table>-->


<table mat-table [dataSource]="dataSource">
  <ng-container *ngFor="let column of displayedColumns" [matColumnDef]="column">
    <mat-header-cell  *matHeaderCellDef>{{ getColumnHeader(column) }}</mat-header-cell>
    <mat-cell   *matCellDef="let element" (dblclick)="cell.onDoubleClick()" >
      <app-operator-salary-cell #cell
                       [cellData]="element[column]"
                       [tableData]="element"
                       [tableId]="this.data[0]['id']"
                       [isInputCell]="inputColumn(element['name'])"
                       (newData)="handleNewData($event)">
      </app-operator-salary-cell>
    </mat-cell>
    <mat-footer-cell  *matFooterCellDef>{{getColumnTotal(column)}}</mat-footer-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
  <mat-footer-row *matFooterRowDef="displayedColumns"></mat-footer-row>
</table>

<!--<p>{{data | json}}</p>-->



