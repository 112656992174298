import {Directive, ElementRef, HostListener, Input} from '@angular/core';

@Directive({
  selector: '[appDateMask]'
})
export class DateMaskDirective {
  @Input()
  public appDateMask: boolean = false;
  constructor(private el: ElementRef) {}

  @HostListener('input', ['$event'])
  onInput() {
    if(this.appDateMask) {
      const inputElement = this.el.nativeElement;
      const inputValue = inputElement.value;
      inputElement.value = this.formatDate(inputValue);
    }
  }

  private formatDate(inputValue: string): string {
    // Implement your own logic to format the date as DD/MM/YYYY
    // Here's a basic example:
    const numericValue = inputValue.replace(/[^0-9]/g, '');
    if (numericValue.length >= 8) {
      const day = numericValue.slice(0, 2);
      const month = numericValue.slice(2, 4);
      const year = numericValue.slice(4, 8);
      return `${day}-${month}-${year}`;
    }
    return inputValue;
  }
}
